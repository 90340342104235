export const APP_URL = window.location.origin;
export const LOGIN_URL = "/login";
export const DASHBOARD_URL = "/dashboard";
export const BOOK_MANAGEMENT = "/books-management";
export const POSTER_MANAGEMENT = "/posters-management";
export const CATEGOORY_MANAGEMENT = "/category-management";
export const CONTACT_MANAGEMENT = "/contactus-management";
export const USER_MANAGEMENT = "/user-management";
export const ORDER_MANAGEMENT = "/order-management";
export const PAYMENT_MANAGEMENT = "/payment-management";
export const SUBSCRIBE_MANAGEMENT = "/subscribe-management";

// Api urls
export const API_URL = process.env.REACT_APP_BASE_URL;
export const LOGIN_API_URL = API_URL + "admin/login";
export const DASHBOARD_COUNT_API_URL = API_URL + "admin/counts";
export const GET_USERS_LIST_API_URL = API_URL + "admin/users-list";
export const UPDATE_USER_API_URL = API_URL + "admin/update-user";
export const LOGOUT_API_URL = API_URL + "user/logout";
export const FILE_UPLOAD_API_URL = API_URL + "file/upload";
export const ADD_PRODUCT_API_URL = API_URL + "admin/add-product";
export const GET_PRODUCT_LISTS_API_URL = API_URL + "admin/product/list";
export const EDIT_PRODUCT_LIST_API_URL = API_URL + "admin/update-product";
export const GET_CONTACT_LIST_API_URL = API_URL + "admin/contact-us/list";
export const ADD_CATEGORY_API_URL = API_URL + "admin/add-category";
export const GET_CONTACT_US_UPDATE = API_URL + "admin/update-contact-us";
export const GET_CATEGORY_LISTS_API_URL = API_URL + "admin/category/list";
export const EDIT_CATEGORY_API_URL = API_URL + "admin/update-category";
export const GET_ORDER_LIST_API_URL = API_URL + "admin/order/list";
export const GET_PAYMENT_LIST_API_URL = API_URL + "admin/payment/list";
export const DELETE_PRODUCT = API_URL + "admin/delete-product";
export const GET_PERMISTIONS = API_URL + "admin/user/permissions";
export const ADD_USER_API_URL = API_URL + "admin/add-user";
export const GET_SUBSCRIBE_LIST_API_URL = API_URL + "admin/subscribe/list";
