const GetFileDetails = async (accessToken, file_id) => {
    const responce = window.axios.get(`${process.env.REACT_APP_BASE_URL}file/detail/${file_id}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization':`Bearer ${accessToken}`
      }}).then(function (result) {
          return result.data;
      }).catch(function (result) {
          return result?.response?.status;
      });
      return responce;
};
  
export default GetFileDetails;