/* eslint-disable */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getAccessToken, logoutUser, checkLogin } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { LOGOUT_API_URL, LOGIN_URL } from "./Constant";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PreviewFiles from "../Common/PreviewFiles";

const Header = (props) => {
    const { profiledata, accessToken } = props;
    const Location = useLocation();
    const dispatch = useDispatch();
    const Navigate = useNavigate();

    // user logout function
    const Logout = () => {
        window.axios.get(LOGOUT_API_URL, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            localStorage.removeItem("accessToken");
            dispatch(getAccessToken(""));
            dispatch(checkLogin("no"));
            dispatch(logoutUser());
            setTimeout(() => Navigate(LOGIN_URL), 100);
            toast.success(result.data.msg);
        })  
        .catch(function (result) {
            if(result.response.data.detail === "Token expired.") {
                localStorage.removeItem("accessToken");
                dispatch(getAccessToken(""));
                dispatch(checkLogin("no"));
                dispatch(logoutUser());
                setTimeout(() => Navigate(LOGIN_URL), 100);
                toast.error(result.response.data.detail);
            }
        });
    };

    useEffect(() => {   
        if(Location.pathname === "/" || Location.pathname === "/login") {
            document.title = "Login";
        } else if (Location.pathname === "/dashboard") {
            document.title = "Dashboard";
        } else if (Location.pathname === "/books-management") {
            document.title = "Books Management";
        } else if (Location.pathname === "/posters-management") {
            document.title = "Posters Management";
        } else if (Location.pathname === "/category-management") {
            document.title = "Category Management";
        } else if (Location.pathname === "/user-management") {
            document.title = "User Management";
        } else if (Location.pathname === "/order-management") {
            document.title = "Order Management";
        } else if (Location.pathname === "/contactus-management") {
            document.title = "Contact Management";
        } else if (Location.pathname === "/subscribe-management") {
            document.title = "Subscribe Management";
        } else {
            document.title = "Payment Management";
        }
    }, [ Location.pathname ]);


    const openSidebar = (e) => {
        e.preventDefault();
        document.getElementById("panel-sidebar").classList.add("show-sidebar");
     }

    return(<div className="page-header d-print-none">
        <div className="col">
         <div className="header-toggle" onClick={(e)=>openSidebar(e)} />
            <h2 className="page-title">
                {Location.pathname === "/dashboard" || Location.pathname === "/" ? "DashBoard" : Location.pathname === "/books-management" ? "Books Management" : Location.pathname === "/contactus-management" ? "Contact Management" : Location.pathname === "/posters-management" ? "Posters Management" : Location.pathname === "/category-management" ? "Category Management" : Location.pathname === "/user-management" ? "User Management" : Location.pathname === "/order-management" ? "Order Management" : Location.pathname === "/subscribe-management" ? "Subscribe Management" : "Payment Management"}
            </h2>
        </div>
        <div className="col-auto ms-auto d-print-none">
            <div className="nav-item dropdown profiledropdown">
                <a className="nav-link d-flex lh-1 text-reset p-0" data-bs-toggle="dropdown" aria-label="Open user menu">
                    <PreviewFiles type="image" url={profiledata?.user_avatar_path} />
                    <div className="d-none d-xl-block ps-2">
                        {profiledata.first_name === null ? profiledata?.email?.split("@")[0] : profiledata?.first_name}
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
                    <a className="dropdown-item" onClick={() => Logout()}>Logout</a>
                </div>
            </div>
        </div>
    </div>)
}

export default Header;