export const ActionTypes = {
    IS_LOGIN: "IS_LOGIN",
    LOGOUT_USER: "LOGOUT_USER",
    GET_PROFILE_USER: "GET_PROFILE_USER",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    USER_ID: "USER_ID",
    USER_LIST: "USER_LIST",
    UPDATE_USER: "UPDATE_USER",
    ID_WISE_USERS: "ID_WISE_USERS",
    ADD_CATEGORY: "ADD_CATEGORY",
    GET_CATEGORY: "GET_CATEGORY",
    ADD_PRODUCT: "ADD_PRODUCT",
    GET_PRODUCT_LIST: "GET_PRODUCT_LIST",
    EDIT_PRODUCT: "EDIT_PRODUCT",
    DELETE_PRODUCT:"DELETE_PRODUCT",
    ID_WISE_CATEGORY: "ID_WISE_CATEGORY",
    EDIT_CATEGORY: "EDIT_CATEGORY",
    GET_ORDER_LIST: "GET_ORDER_LIST",
    ID_WISE_PRODUCTS: "ID_WISE_PRODUCTS",
    GET_PAYMENT_LIST: "GET_PAYMENT_LIST",
    CONTACT_LIST: "CONTACT_LIST",
    PERMISSION_LIST_GET:"PERMISSION_LIST_GET",
    ADD_USER: "ADD_USER",
    GET_SUBSCRIBE_LIST: "GET_SUBSCRIBE_LIST"
};

export const checkLogin = (status) => {
    return {
        type: ActionTypes.IS_LOGIN,
        payload: status,
    }
}

export const getPermissionList = (permissions) => {
    return { 
        type: ActionTypes.PERMISSION_LIST_GET,
        payload: permissions,
    }
}

export const getSubscribeList = (subscribes) => {
    return { 
        type: ActionTypes.GET_SUBSCRIBE_LIST,
        payload: subscribes,
    }
}

export const AddCategory = (category) => {
    return {
        type: ActionTypes.ADD_CATEGORY,
        payload: category,
    }
}

export const GetOrderList = (orders) => {
    return {
        type: ActionTypes.GET_ORDER_LIST,
        payload: orders,
    }
}

export const GetProductIdWiseList = (products) => {
    return {
        type: ActionTypes.ID_WISE_PRODUCTS,
        payload: products,
    }
}

export const GetPaymentList = (payment) => {
    return {
        type: ActionTypes.GET_PAYMENT_LIST,
        payload: payment,
    }
}

export const addNewUser = (user) => {
    return {
        type: ActionTypes.ADD_USER,
        payload: user,
    }
}

export const GetCategory = (categorys) => {
    return {
        type: ActionTypes.GET_CATEGORY,
        payload: categorys,
    }
}

export const EditCategory = (category) => {
    return {
        type: ActionTypes.EDIT_CATEGORY,
        payload: category,
    }
}

export const GetProductList = (products) => {
    return {
        type: ActionTypes.GET_PRODUCT_LIST,
        payload: products,
    }
}

export const AddProduct = (product) => {
    return {
        type: ActionTypes.ADD_PRODUCT,
        payload: product,
    }
}

export const EditProduct = (product) => {
    return {
        type: ActionTypes.EDIT_PRODUCT,
        payload: product,
    }
}

export const deleteProduct = (product) => {
    return {
        type: ActionTypes.DELETE_PRODUCT,
        payload: product,
    }
}

export const logoutUser = () => {
    return {
        type: ActionTypes.LOGOUT_USER,
    }
}

export const getUserId = (id) => {
    return {
        type: ActionTypes.USER_ID,
        payload: id,
    }
}

export const updateUser = (user) => {
    return {
        type: ActionTypes.UPDATE_USER,
        payload: user,
    }
}

export const getIdWiseUserList = (users) => {
    return {
        type: ActionTypes.ID_WISE_USERS,
        payload: users,
    }
}

export const getContactlist = (users) => {
    return {
        type: ActionTypes.CONTACT_LIST,
        payload: users,
    }
}

export const getIdWiseCategoryList = (category) => {
    return {
        type: ActionTypes.ID_WISE_CATEGORY,
        payload: category,
    }
}

export const getProfileUser = (user) => {
    return {
        type: ActionTypes.GET_PROFILE_USER,
        payload: user,
    }
}

export const getAccessToken = (token) => {
    return {
        type: ActionTypes.ACCESS_TOKEN,
        payload: token,
    }
}

export const getUserList = (users) => {
    return {
        type: ActionTypes.USER_LIST,
        payload: users,
    }
}

