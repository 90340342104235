import React from "react";

const CommonLoaderNew = () => {
    return(<div className="commn_loaderset">
        <div className="loaderwrapper">
            <div className="loader"></div>
            <h4>Please Wait....</h4>
        </div>
    </div>)
};

export default CommonLoaderNew;