/* eslint-disable */
import React, { useState } from "react";
import { LOGIN_API_URL, DASHBOARD_URL } from "../Shared/Constant";
import { checkLogin, getAccessToken, getProfileUser, getUserId } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MotivateOnPapeLogo from "../../assets/img/motivateonpapelogo.webp";
import BundleOfBookLogo from "../../assets/img/bundlesofbooks.webp";
import InfoIcon from "../../assets/img/information-button.png";

const Login = () => {
  const dispatch = useDispatch();
  const [ email, setEmail ] = useState("");
  const [ password, setPassword ] = useState("");
  const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  const PasswordRegex = /^(?=.*[0-9])(?=.*[!@$%^&*])[a-zA-Z0-9!@$%^&*]{6,16}$/;
  const Navigate = useNavigate();
  const [ showPassword, setShowPassword ] = useState(false);

  // error message
  const [ errorEmail, setErrorEmail ] = useState("");
  const [ errorPassword, setErrorPassword ] = useState("");

  const SignInBtn = () => {
      if(email !== "" && password !== "") {
          if(EmailRegex.test(email) === true && PasswordRegex.test(password) === true) {
              const jsonData = JSON.stringify({ email: email.toLowerCase(), password: password});
              window.axios.post(LOGIN_API_URL, jsonData, {
                  headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json'
                  }
              }).then(function (result) {
                  dispatch(getProfileUser(result?.data?.data?.user));
                  dispatch(getAccessToken(result?.data?.data?.access_token));
                  dispatch(getUserId(result?.data?.data?.user?.id))
                  dispatch(checkLogin("yes"));
                  setTimeout(() => Navigate(DASHBOARD_URL) , 200);
                  toast.success(result?.data?.msg);
                  ClearFields();
              })  
              .catch(function (result) {
                  dispatch(getProfileUser(""));
                  dispatch(checkLogin("no"));
                  dispatch(getAccessToken(""));
                  toast.error(result?.response?.data?.error);
              });
              setErrorEmail("");
              setErrorPassword("");
          } else {
              if(EmailRegex.test(email) === false) {
                  setErrorEmail("Please Enter Valid Email id");
              } else {
                  setErrorEmail("");
              }
              if(PasswordRegex.test(password) === false) {
                  setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
              } else {
                  setErrorPassword("");
              }
          }
      } else {
          if(email === "") {
              setErrorEmail("Email id cannot be empty");
          } else if(EmailRegex.test(email) === false) {
              setErrorEmail("Please Enter Valid Email id");
          } else {
              setErrorEmail("");
          }
          if (password === "") {
              setErrorPassword("Password cannot be empty");
          } else if(PasswordRegex.test(password) === false) {
              setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
          } else {
              setErrorPassword("");
          }
      }
  };

  // clear field set
  const ClearFields = () => {
    setTimeout(() => {
        setEmail(""); 
        setPassword("");
        setErrorEmail(""); 
        setErrorPassword("");
    }, 500);
  };

  return(<div className="page page-center">
    <div className="container container-tight py-4 logincontainer">
      <div className="text-center mb-1">
        <a href="." className="navbar-brand navbar-brand-autodark loginlogo">
            <img src={process.env.REACT_APP_TITLE === "motivate on pape" ? MotivateOnPapeLogo : BundleOfBookLogo} alt="logo" />
        </a>
      </div>
      <div className="card card-md">
        <div className="card-body login-body">
          <h2 className="h2 text-center mb-4">Login to your account</h2>
          <div className="mb-3">
            <label className="form-label">Email address</label>
            <input type="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Your Email"/>
            {errorEmail && <div className="danger-colorset">{errorEmail}</div>}
          </div>
          <div className="mb-2">
            <label className="form-label">Password
                <div className="passwordnotewrapper">
                    <ol>
                        {/* Minimum 8 characters. */}
                        <li>The alphabet must be between [a-z]</li>
                        <li>At least one alphabet should be of Upper Case [A-Z]</li>
                        <li>At least 1 number or digit between [0-9].</li>
                        <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                    </ol>
                    <img src={InfoIcon} alt="info icon" />
                </div>
            </label>
            <div className="input-group input-group-flat">
              <input type="password" className="d-none" />
              <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Your password" />
              <span className="input-group-text" >
              <a className="link-secondary" onClick={() => setShowPassword(!showPassword)} title="Show password" data-bs-toggle="tooltip">
                  {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>
                  }
                </a>
              </span>
            </div>
            {errorPassword && <div className="danger-colorset">{errorPassword}</div>}
          </div>
          <div className="form-footer">
            <button type="button" className="btn btn-primary w-100" onClick={() => SignInBtn()}>Sign in</button>
          </div>
        </div>
      </div>
    </div>
  </div>)
}

export default Login;