/* eslint-disable */
import React, { useEffect, useState } from "react";
import { CATEGOORY_MANAGEMENT, DASHBOARD_COUNT_API_URL, SUBSCRIBE_MANAGEMENT } from "../Shared/Constant";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const Dashboard = () => {
    const { accessToken, profiledata } = useSelector((state) => state.allReducers);
    const [dashboardDetail,setDashboarddetail] = useState()
    useEffect(() => {
        GetDashBoardStatus();
    }, []);

    const GetDashBoardStatus = () => {
        window.axios.get(`${DASHBOARD_COUNT_API_URL}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setDashboarddetail(result?.data)
        })  
        .catch(function (result) {
        });
    };

    google.charts.load('current', {'packages':['corechart', 'bar']});
    google.charts.setOnLoadCallback(drawChart);
    google.charts.setOnLoadCallback(drawChartUserContact);
    google.charts.setOnLoadCallback(drawChartnNew);

    function drawChart() {
        var data = google.visualization.arrayToDataTable([
          ['Task', 'Hours per Day'],
        //   ['Active', dashboardDetail?.users_count?.active],
          ['Admin', dashboardDetail?.users_count?.admin],
        //   ['Inactive', dashboardDetail?.users_count?.inactive],
          ['Staff', dashboardDetail?.users_count?.staff],
        //   ['Total', dashboardDetail?.users_count?.total],
          ['User', dashboardDetail?.users_count?.user]
        ]);

        var options = {
        //   title: 'Users'
        };
        var chart = new google.visualization.PieChart(document.getElementById('piechart'));
        chart.draw(data, options);
    };

    function drawChartUserContact() {
        var data = google.visualization.arrayToDataTable([
          ['Task', 'Hours per Day'],
        //   ['Active', dashboardDetail?.contact_us_count?.active],
        //   ['Inactive', dashboardDetail?.contact_us_count?.inactive],
          ['Contacted', dashboardDetail?.contact_us_count?.contacted],
          ['Uncontacted', dashboardDetail?.contact_us_count?.uncontacted]
        ]);

        var options = {
        //   title: 'Users'
        };
        var chart = new google.visualization.PieChart(document.getElementById('piechartContactUs'));
        chart.draw(data, options);
    };

    const getLastSixMonth = [];
    var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    var today = dashboardDetail?.past_six_month_payment_count?.past_six_month_count.length > 0 ? new Date(dashboardDetail?.past_six_month_payment_count?.past_six_month_count[dashboardDetail?.past_six_month_payment_count?.past_six_month_count?.length - 1]?.month) : new Date();
    var d;
    var month;
    for(var i = 6; i >= 0; i -= 1) {
        d = new Date(today.getFullYear(), today.getMonth() - i, 1);
        month = monthNames[d.getMonth()];
        getLastSixMonth.push(`${month} 1, ${moment(d).format('YYYY')}`);
    };

    var EmptyMonthArray = [
        [getLastSixMonth[0], 0],
        [getLastSixMonth[1], 0],
        [getLastSixMonth[2], 0],
        [getLastSixMonth[3], 0],
        [getLastSixMonth[4], 0],
        [getLastSixMonth[5], 0],
        [getLastSixMonth[6], 0]
    ];
    
    function drawChartnNew() {
        const PastSixPamentArrays = [['Year', 'count']];
        const PastSixPamentArraysNew = [];

        if(dashboardDetail?.past_six_month_payment_count?.past_six_month_count.length > 0) {
            dashboardDetail?.past_six_month_payment_count?.past_six_month_count.filter((elm) => {
                PastSixPamentArraysNew.push([moment.utc(elm.month).local().format('MMMM D, YYYY'), elm.count]);
            });
            const PastData_ = PastSixPamentArraysNew.map((elm) => elm[0].split(" ")[0]).toString();
            EmptyMonthArray?.filter((elm) => {
                const currentValue = elm[0].split(" ")[0];
                if(PastData_.includes(currentValue) !== true) {
                    PastSixPamentArraysNew.push(elm);
                };
            });
        };

        function sortByMonth(arr) {
            var months = ["January", "February", "March", "April", "May", "June",
                        "July", "August", "September", "October", "November", "December"];
            arr.sort(function(a, b){
                return months.indexOf(a[0].split(" ")[0])
                     - months.indexOf(b[0].split(" ")[0]);
            });
        }

        sortByMonth(PastSixPamentArraysNew);
        
        var data = google.visualization.arrayToDataTable(PastSixPamentArrays.concat(PastSixPamentArraysNew));
        
        var options = {
            chart: {
                // title: 'Company Performance',
                // subtitle: 'Sales, Expenses, and Profit: 2014-2017',
            }
        };
        var chart = new google.charts.Bar(document.getElementById('columnchart_material'));
        chart.draw(data, google.charts.Bar.convertOptions(options));
    };

    return(<div className="page-mainset">
        <div className="sashboardstatuscountswrapper">
            {(profiledata?.is_admin || (profiledata?.permission?.includes("user-list") || profiledata?.permission?.includes("add-user") || profiledata?.permission?.includes("update-user"))) && (<Link style={{width:"32.33%"}} to={"/user-management"}>
                <div className="card-body">
                    <h4 className="dashtitle">Users</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.users_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Active</div>
                            <div className="h1 mb-3">{dashboardDetail?.users_count?.active}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Inactive</div>
                            <div className="h1 mb-3">{dashboardDetail?.users_count?.inactive}</div>
                        </div>
                    </div>
                </div>
            </Link>)}
            {(profiledata?.is_admin || (profiledata?.permission?.includes("product-list") || profiledata?.permission?.includes("add-product") || profiledata?.permission?.includes("update-product") || profiledata?.permission?.includes("delete-product"))) && (<Link style={{width:"32.33%"}} to={"/posters-management"}>
                <div className="card-body">
                    <h4 className="dashtitle">{process.env.REACT_APP_TITLE === "motivate on pape" ? "Posters" : "Books"}</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.products_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Active</div>
                            <div className="h1 mb-3">{dashboardDetail?.products_count?.active}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Inactive</div>
                            <div className="h1 mb-3">{dashboardDetail?.products_count?.inactive}</div>
                        </div>
                    </div>
                </div>
            </Link>)}
            {(profiledata?.is_admin || (profiledata?.permission?.includes("category-products-list") || profiledata?.permission?.includes("add-category-products") || profiledata?.permission?.includes("update-category-products") || profiledata?.permission?.includes("delete-product"))) && (<>
                {process.env.REACT_APP_TITLE === "bundles of books" && (<Link style={{width:"32.33%"}} to={"/category-management"}>
                <div className="card-body">
                    <h4 className="dashtitle">Category</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.product_category_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Active</div>
                            <div className="h1 mb-3">{dashboardDetail?.product_category_count?.active}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Inactive</div>
                            <div className="h1 mb-3">{dashboardDetail?.product_category_count?.inactive}</div>
                        </div>
                    </div>
                </div>
            </Link>)}
            </>)}
            {(profiledata?.is_admin || (profiledata?.permission?.includes("order-list"))) && (<Link style={{width:"32.33%"}} to={"/order-management"}>
                <div className="card-body">
                    <h4 className="dashtitle">Ordered</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.order_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Paid</div>
                            <div className="h1 mb-3">{dashboardDetail?.order_count?.paid}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Unpaid</div>
                            <div className="h1 mb-3">{dashboardDetail?.order_count?.unpaid}</div>
                        </div>
                    </div>
                </div>
            </Link>)}
            {(profiledata?.is_admin || (profiledata?.permission?.includes("payment-list"))) && (<Link style={{width:"32.33%"}} to={"/payment-management"}>
                <div className="card-body">
                    <h4 className="dashtitle">Payment</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.payment_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Paid</div>
                            <div className="h1 mb-3">{dashboardDetail?.payment_count?.paid}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Unpaid</div>
                            <div className="h1 mb-3">{dashboardDetail?.payment_count?.unpaid}</div>
                        </div>
                    </div>
                </div>       
            </Link>)}  
            {(profiledata?.is_admin || (profiledata?.permission?.includes("contact-us-list") || profiledata?.permission?.includes("update-contact-us"))) && (<Link to={"/contactus-management"} style={{width:"32.33%"}}>
                <div className="card-body">
                    <h4 className="dashtitle">Contacts</h4>
                    <div className="columnflex" style={{ justifyContent: "flex-start", columnGap: "10px" }}>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.contact_us_count?.total}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Contacted</div>
                            <div className="h1 mb-3">{dashboardDetail?.contact_us_count?.contacted}</div>
                        </div>
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Un contacted</div>
                            <div className="h1 mb-3">{dashboardDetail?.contact_us_count?.uncontacted}</div>
                        </div>
                    </div>
                </div>
            </Link>)}    
            {(profiledata?.is_admin || (profiledata?.permission?.includes("subscribe-list"))) && (<Link style={{width:"32.33%"}} to={SUBSCRIBE_MANAGEMENT}>
                <div className="card-body">
                    <h4 className="dashtitle">Subscribes</h4>
                    <div className="columnflex">
                        <div className="columnssewrapper" id="columnssewrapperid">
                            <div className="subheader">Total</div>
                            <div className="h1 mb-3">{dashboardDetail?.subscribe_mail_count?.total}</div>
                        </div>
                    </div>
                </div>       
            </Link>)}    
        </div>
        <div className="commn-table-set">
            <div className="row">
                {(profiledata?.is_admin || (profiledata?.permission?.includes("user-list") || profiledata?.permission?.includes("add-user") || profiledata?.permission?.includes("update-user"))) && (<div className="col-md-6">
                    <div className="card-body">
                        <div className="card-header">
                            <div className="card-title">Total Users</div>
                        </div>
                        <div className="card-body" style={{ padding: "0px" }}>
                            <div className="d-flex align-items-center">
                                <div className="ms-auto lh-1 d-none">
                                    <span className="text-muted" style={{ textAlign: "right" }}>Last 7 days</span>
                                </div>
                            </div>
                            <div id="piechart" style={{ width: '100%', height: '300px' }}></div>
                        </div>
                    </div>
                </div>)}
                {(profiledata?.is_admin || (profiledata?.permission?.includes("contact-us-list") || profiledata?.permission?.includes("update-contact-us"))) && (<div className="col-md-6">
                    <div className="card-body">
                        <div className="card-header">
                            <div className="card-title">Total Contact</div>
                        </div>
                        <div className="card-body" style={{ padding: "0px" }}>
                            <div className="d-flex align-items-center">
                                <div className="ms-auto lh-1 d-none">
                                    <span className="text-muted" style={{ textAlign: "right" }}>Last 7 days</span>
                                </div>
                            </div>
                            <div id="piechartContactUs" style={{ width: '100%', height: '300px' }}></div>
                        </div>
                    </div>
                </div>)}
                <div className="col-md-12">
                    <div className="card-body">
                        <div className="card-header">
                            <div className="card-title">Past Six Month Payment</div>
                        </div>
                        <div className="card-body" style={{ padding: "0px" }}>
                            <div className="d-flex align-items-center">
                                <div className="ms-auto lh-1 d-none">
                                    <span className="text-muted" style={{ textAlign: "right" }}>Last 7 days</span>
                                </div>
                            </div>
                            <div id="columnchart_material" style={{ width: '100%', height: '400px' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Dashboard;