/* eslint-disable */
import React, { useState, useEffect } from "react";
import CommonPopup from "../Common/CommonPopup";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { GET_SUBSCRIBE_LIST_API_URL } from "../Shared/Constant";
import { getSubscribeList } from "../../redux/actions";
import PaginationPage from "../Common/PaginationPage";
import CloseIcon from '../../assets/img/closebutton.png';
import CommonLoaderNew from "../Common/CommonLoaderNew";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const SubscribeManagement = () => {
    const dispatch = useDispatch();
    const [ ModalCommon, setModalCommon ] = useState({
        open: false,
        title: "",
        submitbtn: "",
        cancelbtn: "",
        modaltype: "",
        description: ""
    });
    const { accessToken, subscribesList, profiledata } = useSelector((state) => state.allReducers);
    const [ categoryType, setCategoryType ] = useState("all");
    const [ userLimit, setUserLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ categorySearch, setCategorySearch ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ searchNone, setSearchNone ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setCategorySearch("");
        setSearchNone(true);
    };

    // category type
    const SelectCategoryTypeFnct = (e) => {
        setCategoryType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        GetCategoryListFnct();
    }, [ userLimit, currentPage, categoryType, searchNone ]);

    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                GetCategoryListFnct(1);
            } else if(categorySearch === "" || categorySearch?.length <= 1) {
                GetCategoryListFnct();
            };
        };
    };

    // get category list
    const GetCategoryListFnct = (pages) => {
        setListLoader(true);
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        window.axios.get(`${GET_SUBSCRIBE_LIST_API_URL}/${categoryType}/${userLimit}/${pages === undefined ? currentPage : pages}/${categorySearch === "" ? "none" : categorySearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            setSearchNone(false);
            dispatch(getSubscribeList(result.data.data));
            setPaginationLength(result.data.pagination);
        })  
        .catch(function (result) {
            setListLoader(false);
            dispatch(getSubscribeList([]));
        });
    };

    // category true or false
    // const CategoryActiveInfo = (category) => {
    //     setModalCommon({
    //         ...ModalCommon,
    //         open: !ModalCommon?.open,
    //         title: "Are you sure?",
    //         description: category?.is_active === true ? 'Do you really want to deactivate category to' : 'Do you really want to active category to',
    //         submitbtn: category?.is_active === true ? "Yes" : "Yes",
    //         cancelbtn: "No",
    //         modaltype: "permision",
    //         categorydetails: category,
    //         type: "is active category"
    //     });
    // };

    // category limit function
    const CategryLimit = (e) => {
        setUserLimit(e.target.value);
        setCurrentPage(1);
    };

    return(<div className="col-sm-12 col-lg-12">
    <div className="card-body">
        <div className="card-header-New">
            <div className="searchBoxwithbtn">
                {categorySearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                <input type="text" className="form-control" value={categorySearch} onKeyDown={(e) => onEnterSearch(e)}  onChange={(e) => setCategorySearch(e.target.value)} placeholder="Search email ..."/>
                {categorySearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
            </div>
            <div className="card-header-right">
                {/* <select className="form-control activestatusselet" value={categoryType} onChange={(e) => SelectCategoryTypeFnct(e)}>
                    <option value="all">All</option>
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                </select> */}
                <select className="form-control userLimitselect" value={userLimit} onChange={(e) => CategryLimit(e)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
            </div>
        </div>
        <div className="card-table-new">
            <div className="card-table table-responsive">
                <table className="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Email</th>
                            <th>Created At</th>
                            <th>Updated At</th>
                            {/* {(profiledata?.is_admin || (profiledata?.permission?.includes("update-product-category"))) && (<th>Is Active</th>)} */}
                        </tr>
                    </thead>
                    <tbody>
                        {subscribesList && subscribesList?.length === 0 ? (<tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                        </tr>) : subscribesList?.map((category, index) => {
                            return(<tr key={index}>
                                <td>{category.email}</td>
                                <td>{timeSince(new Date(category.created_at))}</td>
                                <td>{timeSince(new Date(category.updated_at))}</td>
                                {/* {(profiledata?.is_admin || (profiledata?.permission?.includes("update-product-category"))) && (<td>
                                    <label className="form-check form-switch">
                                        <input className="form-check-input"  type="checkbox" onChange={e => {}} onClick={() => CategoryActiveInfo(category)} checked={category?.is_active === true ? true : false} />
                                        <span className="form-check-label" >
                                            <div className="post__content" dangerouslySetInnerHTML={{ __html: category?.is_active === true ? "<span class='badge bg-green-lt'>Yes</span>" : "<span class='badge bg-red-lt'>No</span>"}}></div>
                                        </span>
                                    </label>
                                </td>)} */}
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <PaginationPage 
                paginationLength={paginationLength}
                totalPages={paginationLength?.total_records}
                currentPage={currentPage}
                onChangePage={currentFunction}
            />
        </div>
        
    </div>
    {ListLoader && (<CommonLoaderNew />)}
    {/* common modal */}
    {ModalCommon?.open && (<CommonPopup ModalCommon={ModalCommon} setModalCommon={setModalCommon}/>)}
</div>)
}

export default SubscribeManagement;