/* eslint-disable */
import React, { useState, useEffect } from "react";
import DummyImage from '../../../assets/img/dummyimage.png';
import DeleteModalIcon from '../../../assets/img/deletepopup.png';
import { API_URL, UPDATE_USER_API_URL, EDIT_CATEGORY_API_URL, ADD_USER_API_URL, ADD_CATEGORY_API_URL, FILE_UPLOAD_API_URL, ADD_PRODUCT_API_URL, GET_CATEGORY_LISTS_API_URL, EDIT_PRODUCT_LIST_API_URL, DELETE_PRODUCT } from "../../Shared/Constant";
import { useDispatch, useSelector } from "react-redux";
import { updateUser, AddCategory, AddProduct, EditProduct, addNewUser, EditCategory, deleteProduct } from "../../../redux/actions";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageLoader from "../../../assets/img/spinner_transferent.svg";
import InfoIcon from "../../../assets/img/information-button.png";
import Multiselect from 'multiselect-react-dropdown';
import GetFileDetails from "../../../Api/GetFileDetails";
import { DefaultEditor } from "react-simple-wysiwyg";
import DummyPostersImage from '../../../assets/img/DefaultProduct.jpg';
import DummyBooksImage from '../../../assets/img/dummybundlesbook.jpg';
import CountrySelectInput from "../CountrySelectInput/CountryMobileInput";

const CommonPopup = (props) => {
    const dispatch = useDispatch();
    const { ModalCommon, setModalCommon, userObjects, setCommonLoaderState } = props;
    const { accessToken, categoryObjects, permissionlits } = useSelector((state) => state.allReducers);    
    const [ EditUser, setEditUser ] = useState({
        fname: ModalCommon?.selectedUser?.first_name === undefined ? "" : ModalCommon?.selectedUser?.first_name,
        lname: ModalCommon?.selectedUser?.last_name === undefined ? "" : ModalCommon?.selectedUser?.last_name,
        email: ModalCommon?.selectedUser?.email === undefined ? "" : ModalCommon?.selectedUser?.email,
        permission: (ModalCommon?.selectedUser?.permission === "" || ModalCommon?.selectedUser?.permission === undefined || ModalCommon?.selectedUser?.permission === null) ? "" : ModalCommon?.selectedUser?.permission
    });
    const [ isAdmin, setIsAdmin ] = useState(ModalCommon?.selectedUser?.is_admin === undefined ? false : ModalCommon?.selectedUser?.is_admin);
    const [ isStaff, setIsStaff ] = useState(ModalCommon?.selectedUser?.is_staff === undefined ? false : ModalCommon?.selectedUser?.is_staff);
    const [ password, setPassword ] = useState("");
    const [ showPassword, setShowPassword ] = useState(false);
    const [ selectedValueUser, setselectedValueUser ] = useState((ModalCommon?.selectedUser?.permission === null || ModalCommon?.selectedUser?.permission === undefined) ? "" : ModalCommon?.selectedUser?.permission?.split(","));

    const [ MobileNo, setMobileNo ] = useState({
        countrycode: (ModalCommon?.selectedUser?.phone === undefined || ModalCommon?.selectedUser?.phone === null) ? "1" : parseInt(ModalCommon?.selectedUser?.phone?.split(" ")[0]),
        number: (ModalCommon?.selectedUser?.phone === undefined || ModalCommon?.selectedUser?.phone === null) ? "" : parseInt(ModalCommon?.selectedUser?.phone?.split(" ")[1]),
    });
    const [ MobileError, setMobileError ] = useState("");
    const [ categoryName, setCategoryName ] = useState(ModalCommon?.categorydetails?.name === undefined ? "" : ModalCommon?.categorydetails?.name);
    const [ fileUploadLoader, setFileUploadLoader ] = useState(false);
    const [ UploadLoader, setUploadLoader ] = useState(false);
    const [ addBooksState, setAddBooksState ] = useState({
        name: ModalCommon?.bookdetails?.name === undefined ? "" : ModalCommon?.bookdetails?.name,
        description: ModalCommon?.bookdetails?.description === undefined ? "" : ModalCommon?.bookdetails?.description,
        category: ModalCommon?.bookdetails?.category === undefined ? "" : ModalCommon?.bookdetails?.category,
        product_image: ModalCommon?.bookdetails?.product_image === undefined ? "" : ModalCommon?.bookdetails?.product_image,
        product_file: ModalCommon?.bookdetails?.product_file === undefined ? "" : ModalCommon?.bookdetails?.product_file,
        price: ModalCommon?.bookdetails?.price === undefined ? "" : ModalCommon?.bookdetails?.price,
    });
    const [ fileUpload, setFileUpload ] = useState("");
    const [ profileUpload, setProfileUpload ] = useState(ModalCommon.title === "Edit Book" ? ModalCommon?.bookdetails?.product_img_path : "");
    const [ bookFieldError, setBookFieldError ] = useState(false);
    const EmailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
    const PasswordRegex = /^(?=.*[0-9])(?=.*[!@$%^&*])[a-zA-Z0-9!@$%^&*]{6,16}$/;
    const SelectedUser = userObjects !== undefined && userObjects[ModalCommon?.selectedUser?.id];
    // error message
    const [errorFname, setErrorFname ] = useState("");
    const [errorLname, setErrorLname ] = useState("");
    const [ errorEmail, setErrorEmail ] = useState("");
    const [errorPassword, setErrorPassword] = useState("");
    const [errorPermission, setErrorPermission] = useState("");
    const [ errorCategory, setErrorCategory ] = useState("");
    const [ categoryListShow, setCategoryListShow ] = useState([]);
    const [ selectedValue, setselectedValue ] = useState(ModalCommon?.bookdetails === "" ? "" : ModalCommon?.bookdetails?.category?.split(',').map((elm) => categoryObjects[elm]));

    // upload avatar image
    const UploadAvatarImage = (e) => {
        let img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        img.onload = () => {
            if(img.width > img.height) {
                setFileUploadLoader(true);
                setCommonLoaderState(true);
                const files = e.target.files[0];
                const formData = new FormData();
                formData.append("file", files, files.name);

                window.axios.post( FILE_UPLOAD_API_URL, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    if (result.data) {
                        setAddBooksState({...addBooksState, product_image: result.data.id});
                        setTimeout(() => {
                            setFileUploadLoader(false);
                            setCommonLoaderState(false);
                        }, 500);
                        setProfileUpload(result.data.file_url_350);
                    }
                }).catch(function (result) {
                    setTimeout(() => {
                        setFileUploadLoader(false);
                        setCommonLoaderState(false);
                    }, 500);
                });
            } else {
                toast.error("Image height must be less than width");
            }
        }
    };

    // upload book files
    const UploadBookFile = (e) => {
        setUploadLoader(true);
        setCommonLoaderState(true);
        const files = e.target.files[0];
        const formData = new FormData();
        formData.append("file", files, files.name);

        window.axios.post( FILE_UPLOAD_API_URL, formData, {
            headers: {
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            if (result.data) {
                setTimeout(() => {
                    setUploadLoader(false);
                    setCommonLoaderState(false);
                }, 500);
                setFileUpload(files);
                setAddBooksState({...addBooksState, product_file: result.data.id})
            }
        }).catch(function (result) {
            setTimeout(() => {
                setUploadLoader(false);
                setCommonLoaderState(false);
            }, 500);
        });
    };

    useEffect(() => {
        if(process.env.REACT_APP_TITLE === "bundles of books") {
            if(ModalCommon?.title === "Add Book" || ModalCommon?.title === "Edit Book") {
                window.axios.get(`${GET_CATEGORY_LISTS_API_URL}/all/5000/1/none`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    // Only active category get
                    let getList = result?.data?.data?.filter((item)=>item?.is_active === true);
                    setCategoryListShow(getList);
                })  
                .catch(function (result) {
                    setCategoryListShow([]);
                });
            }
        }
    }, [ ModalCommon ]);

    useEffect(() => {
        if(ModalCommon?.title === "Edit Book" && ModalCommon?.type === "Edit Book") {
            GetFileDatas();
        }
    }, [ModalCommon?.bookdetails?.product_file]);

    // get file details function
    const GetFileDatas = async () => {
        const UserName = await GetFileDetails(accessToken, ModalCommon?.bookdetails?.product_file);
        setFileUpload(UserName);
    };

    // submit button
    const SubmitCommonBtn = () => {
        if(ModalCommon?.title === "Edit User" && ModalCommon?.type === "Edit User") {
            if(isStaff === true && isAdmin === false) {
                if((EditUser.email !== (SelectedUser.email === null ? "" : SelectedUser.email)) || 
                (EditUser.fname !== (SelectedUser.first_name === null ? "" : SelectedUser.first_name)) || 
                (EditUser.lname !== (SelectedUser.last_name === null ? "" : SelectedUser.last_name)) || 
                (EditUser.phone !== (SelectedUser.phone === null ? "" : SelectedUser.phone)) ||
                (EditUser.permission !== (SelectedUser.permission === null ? "" : SelectedUser.permission)) && 
                MobileNo?.number?.length === 10
                ) {
                    if(EmailRegex.test(EditUser?.email) === true && EditUser.permission !== "") {
                        const jsonData = JSON.stringify({
                            first_name: EditUser?.fname, 
                            last_name: EditUser?.lname, 
                            phone: MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(), 
                            email: EditUser?.email.toLowerCase(), 
                            user_id: ModalCommon?.selectedUser?.id,
                            is_admin: isAdmin === false ? 0 : 1,
                            is_staff: isStaff === false ? 0 : 1,
                            permission: EditUser?.permission === "" ? "none" : EditUser?.permission
                        });
                        window.axios.post(UPDATE_USER_API_URL, jsonData, {
                            headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json',
                            'Authorization': 'Bearer ' + accessToken,
                            }
                        }).then(function (result) {
                            dispatch(updateUser(result?.data?.data));
                            setModalCommon({...ModalCommon, 
                                open: false,
                                title: "",
                                submitbtn: "",
                                cancelbtn: "",
                                modaltype: "",
                                type: "",
                                bookdetails: "",
                            });
                            toast.success(result?.data?.msg);
                        })  
                        .catch(function (result) {
                            toast.error(result.response.data.error);
                        });
                        setErrorEmail("");
                    } else {
                        if(EmailRegex.test(EditUser?.email) === false) {
                            setErrorEmail("Please Enter Valid Email id");
                        } else {
                            setErrorEmail("");
                        }
                        if (EditUser.permission === "") {
                            setErrorPermission("Please select permission");
                        } else {
                            setErrorPermission("");
                        }
                    }
                } else {
                    if(EditUser?.email === "") {
                        setErrorEmail("Email id cannot be empty");
                    } else if(EmailRegex.test(EditUser?.email) === false) {
                        setErrorEmail("Please Enter Valid Email id");
                    } else {
                        setErrorEmail("");
                    }
                    if(MobileNo?.number === "") {
                        setMobileError("Phone Number cannot be empty");
                    } else if(MobileNo?.number?.length < 10) {
                        setMobileError("Please enter 10 digit number");
                    } else {
                        setMobileError("");
                    }
                    if (EditUser.permission === "") {
                        setErrorPermission("Please select permission");
                    } else {
                        setErrorPermission("");
                    }
                }
            } else {
                if((EditUser.email !== (SelectedUser.email === null ? "" : SelectedUser.email)) || 
                   (EditUser.fname !== (SelectedUser.first_name === null ? "" : SelectedUser.first_name)) || 
                   (EditUser.lname !== (SelectedUser.last_name === null ? "" : SelectedUser.last_name)) || 
                   (EditUser.phone !== (SelectedUser.phone === null ? "" : SelectedUser.phone)) ||
                   MobileNo?.number?.length === 10
                ) {
                    if (EmailRegex.test(EditUser?.email) === true) {
                        const jsonData = JSON.stringify({ 
                            first_name: EditUser?.fname, 
                            last_name: EditUser?.lname, 
                            phone: MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(), 
                            email: EditUser?.email.toLowerCase(), 
                            user_id: ModalCommon?.selectedUser?.id,
                            is_admin: isAdmin === false ? 0 : 1,
                            is_staff: isStaff === false ? 0 : 1,
                        });
                        window.axios.post(UPDATE_USER_API_URL, jsonData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + accessToken,
                            }
                        }).then(function (result) {
                            dispatch(updateUser(result?.data?.data));
                            setModalCommon({
                                ...ModalCommon,
                                open: false,
                                title: "",
                                submitbtn: "",
                                cancelbtn: "",
                                modaltype: "",
                                type: "",
                                details: "",
                            });
                            toast.success(result?.data?.msg);
                        }).catch(function (result) {
                                toast.error(result?.response?.data?.error);
                                if (result?.response?.status === 403) {
                                    dispatch(clearRedux());
                                    navigate(LOGIN_URL);
                                }
                            });
                        setErrorEmail("");
                    } else {
                        if (EmailRegex.test(EditUser?.email) === false) {
                            setErrorEmail("Please Enter Valid Email id");
                        } else {
                            setErrorEmail("");
                        }
                    }
                } else {
                    if (EditUser?.email === "") {
                        setErrorEmail("Email id cannot be empty");
                    } else if (EmailRegex.test(EditUser?.email) === false) {
                        setErrorEmail("Please Enter Valid Email id");
                    } else {
                        setErrorEmail("");
                    }
                    if(MobileNo?.number === "") {
                        setMobileError("Phone Number cannot be empty");
                    } else if(MobileNo?.number?.length < 10) {
                        setMobileError("Please enter 10 digit number");
                    } else {
                        setMobileError("");
                    }
                }
            }
        } else if(ModalCommon?.title === "Add User" && ModalCommon?.type === "Add User") {
            if(isStaff === true) {
                if (EditUser.email !== "" && EditUser.fname !== "" && EditUser.lname !== "" && 
                EditUser.phone !== "" && EditUser.permission !== "" && password !== "" && MobileNo?.number?.length === 10
                ) {
                    if (EmailRegex.test(EditUser?.email) === true && PasswordRegex.test(password) === true) {
                        const jsonData = JSON.stringify({ 
                            first_name: EditUser?.fname, 
                            last_name: EditUser?.lname, 
                            phone: MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(), 
                            email: EditUser?.email.toLowerCase(), 
                            user_id: ModalCommon?.selectedUser?.id,
                            is_admin: isAdmin,
                            is_staff: isStaff,
                            permission: EditUser?.permission === "" ? "none" : EditUser?.permission,
                            password: password
                        });
                        window.axios.post(ADD_USER_API_URL, jsonData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + accessToken,
                            }
                        }).then(function (result) {
                            dispatch(addNewUser(result?.data?.data));
                            setModalCommon({
                                ...ModalCommon,
                                open: false,
                                title: "",
                                submitbtn: "",
                                cancelbtn: "",
                                modaltype: "",
                                type: "",
                                details: "",
                            });
                            toast.success(result?.data?.msg);
                        }).catch(function (result) {
                            toast.error(result?.response?.data?.error);
                            if (result?.response?.status === 403) {
                                dispatch(clearRedux());
                                navigate(LOGIN_URL);
                            }
                        });
                        setErrorEmail("");
                        setErrorPassword("");
                        setErrorPermission("");
                    } else {
                        if (EmailRegex.test(EditUser?.email) === false) {
                            setErrorEmail("Please Enter Valid Email id");
                        } else {
                            setErrorEmail("");
                        }
                        if (PasswordRegex.test(password) === false) {
                            setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                        } else {
                            setErrorPassword("");
                        }
                    }
                } else {
                    if (EditUser.permission === "") {
                        setErrorPermission("Please select permission");
                    } else {
                        setErrorPermission("");
                    }
                    if(EditUser?.fname === "") {
                        setErrorFname("First name cannot be empty");
                    } else {
                        setErrorFname("");
                    }
                    if(EditUser?.lname === "") {
                        setErrorLname("Last name cannot be empty");
                    } else {
                        setErrorLname("");
                    }
                    if (EditUser?.email === "") {
                        setErrorEmail("Email id cannot be empty");
                    } else if (EmailRegex.test(EditUser?.email) === false) {
                        setErrorEmail("Please Enter Valid Email id");
                    } else {
                        setErrorEmail("");
                    }
                    if(MobileNo?.number === "") {
                        setMobileError("Phone Number cannot be empty");
                    } else if(MobileNo?.number?.length < 10) {
                        setMobileError("Please enter 10 digit number");
                    } else {
                        setMobileError("");
                    }
                    if (password === "") {
                        setErrorPassword("Password cannot be empty");
                    } else if (PasswordRegex.test(password) === false) {
                        setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                    } else {
                        setErrorPassword("");
                    }
                }
            } else {
                if (EditUser.email !== "" && EditUser.fname !== "" && EditUser.lname !== "" && 
                EditUser.phone !== "" && password !== "" && MobileNo?.number?.length === 10
                ) {
                    if (EmailRegex.test(EditUser?.email) === true && PasswordRegex.test(password) === true) {
                        const jsonData = JSON.stringify({ 
                            first_name: EditUser?.fname, 
                            last_name: EditUser?.lname, 
                            phone: MobileNo?.countrycode.toString() + " " + MobileNo?.number.toString(), 
                            email: EditUser?.email.toLowerCase(), 
                            user_id: ModalCommon?.selectedUser?.id,
                            is_admin: isAdmin,
                            is_staff: isStaff,
                            permission: EditUser?.permission === "" ? "none" : EditUser?.permission,
                            password: password
                        });
                        window.axios.post(ADD_USER_API_URL, jsonData, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Accept': 'application/json',
                                'Authorization': 'Bearer ' + accessToken,
                            }
                        }).then(function (result) {
                            dispatch(addNewUser(result?.data?.data));
                            setModalCommon({
                                ...ModalCommon,
                                open: false,
                                title: "",
                                submitbtn: "",
                                cancelbtn: "",
                                modaltype: "",
                                type: "",
                                details: "",
                            });
                            toast.success(result?.data?.msg);
                        }).catch(function (result) {
                            toast.error(result?.response?.data?.error);
                            if (result?.response?.status === 403) {
                                dispatch(clearRedux());
                                navigate(LOGIN_URL);
                            }
                        });
                        setErrorEmail("");
                        setErrorPassword("");
                        setErrorPermission("");
                    } else {
                        if (EmailRegex.test(EditUser?.email) === false) {
                            setErrorEmail("Please Enter Valid Email id");
                        } else {
                            setErrorEmail("");
                        }
                        if (PasswordRegex.test(password) === false) {
                            setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                        } else {
                            setErrorPassword("");
                        }
                    }
                } else {
                    if (EditUser.permission === "") {
                        setErrorPermission("Please select permission");
                    } else {
                        setErrorPermission("");
                    }
                    if(EditUser?.fname === "") {
                        setErrorFname("First name cannot be empty");
                    } else {
                        setErrorFname("");
                    }
                    if(EditUser?.lname === "") {
                        setErrorLname("Last name cannot be empty");
                    } else {
                        setErrorLname("");
                    }
                    if (EditUser?.email === "") {
                        setErrorEmail("Email id cannot be empty");
                    } else if (EmailRegex.test(EditUser?.email) === false) {
                        setErrorEmail("Please Enter Valid Email id");
                    } else {
                        setErrorEmail("");
                    }
                    if(MobileNo?.number === "") {
                        setMobileError("Phone Number cannot be empty");
                    } else if(MobileNo?.number?.length < 10) {
                        setMobileError("Please enter 10 digit number");
                    } else {
                        setMobileError("");
                    }
                    if (password === "") {
                        setErrorPassword("Password cannot be empty");
                    } else if (PasswordRegex.test(password) === false) {
                        setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
                    } else {
                        setErrorPassword("");
                    }
                }
            }
            
        } else if (ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is admin user") {
            const jsonData = JSON.stringify({is_admin: ModalCommon?.selectedUser?.is_admin === false ? 1 : 0 , user_id: ModalCommon?.selectedUser?.id});
            window.axios.post(UPDATE_USER_API_URL, jsonData, {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(updateUser(result?.data?.data));
                setModalCommon({...ModalCommon, 
                    open: false,
                    title: "",
                    submitbtn: "",
                    cancelbtn: "",
                    modaltype: "",
                    type: "",
                    bookdetails: "",
                });
                toast.success(result?.data?.msg);
            })  
            .catch(function (result) {
                toast.error(result.response.data.error);
            });
        } else if (ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is staff user") {
            const jsonData = JSON.stringify({ is_staff: ModalCommon?.selectedUser?.is_staff === false ? 1 : 0, user_id: ModalCommon?.selectedUser?.id, permission: "none"});
            window.axios.post(UPDATE_USER_API_URL, jsonData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(updateUser(result?.data?.data));
                setModalCommon({
                    ...ModalCommon,
                    open: false,
                    title: "",
                    submitbtn: "",
                    cancelbtn: "",
                    modaltype: "",
                    type: "",
                    details: "",
                });
                toast.success(result?.data?.msg);
            }).catch(function (result) {
                toast.error(result?.response?.data?.error);
                if (result?.response?.status === 403) {
                    dispatch(clearRedux());
                    navigate(LOGIN_URL);
                }
            });
        } else if(ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is active user") {
            const jsonData = JSON.stringify({is_active: ModalCommon?.selectedUser?.is_active === false ? 1 : 0 , user_id: ModalCommon?.selectedUser?.id});
            window.axios.post(UPDATE_USER_API_URL, jsonData, {
                headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(updateUser(result?.data?.data));
                setModalCommon({...ModalCommon, 
                    open: false,
                    title: "",
                    submitbtn: "",
                    cancelbtn: "",
                    modaltype: "",
                    type: "",
                    bookdetails: "",
                });
                toast.success(result?.data?.msg);
            })  
            .catch(function (result) {
                toast.error(result.response.data.error);
            });
        } else if(ModalCommon?.title === "Add Category" && ModalCommon?.type === "Add Category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({ name: categoryName });
                window.axios.post(ADD_CATEGORY_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    dispatch(AddCategory(result?.data?.data));
                    setModalCommon({...ModalCommon, 
                        open: false,
                        title: "",
                        submitbtn: "",
                        cancelbtn: "",
                        modaltype: "",
                        type: "",
                        categorydetails: "",
                    });
                    setCategoryName("");
                    setErrorCategory("");
                    toast.success("Category added successfully");
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
            } else {
                setErrorCategory("Category name cannot be empty")
            }
        } else if(ModalCommon?.title === "Edit Category" && ModalCommon?.type === "Edit Category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({ name: categoryName, category_id: ModalCommon?.categorydetails?.id });
                window.axios.post(EDIT_CATEGORY_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    dispatch(EditCategory(result?.data?.data));
                    setModalCommon({...ModalCommon, 
                        open: false,
                        title: "",
                        submitbtn: "",
                        cancelbtn: "",
                        modaltype: "",
                        type: "",
                        categorydetails: "",
                    });
                    setCategoryName("");
                    setErrorCategory("");
                    toast.success("Category updated successfully");
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
            } else {
                setErrorCategory("Category name cannot be empty")
            }
        } else if(ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is active category") {
            if(categoryName !== "") {
                const jsonData = JSON.stringify({ 
                    is_active: ModalCommon?.categorydetails?.is_active === false ? 1 : 0, 
                    category_id: ModalCommon?.categorydetails?.id, 
                });
                window.axios.post(EDIT_CATEGORY_API_URL, jsonData, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    dispatch(EditCategory(result?.data?.data));
                    setModalCommon({...ModalCommon, 
                        open: false,
                        title: "",
                        submitbtn: "",
                        cancelbtn: "",
                        modaltype: "",
                        type: "",
                        categorydetails: "",
                    });
                    setCategoryName("");
                    setErrorCategory("");
                    toast.success(result.data.msg);
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
            } else {
                setErrorCategory("Category name cannot be empty")
            }
        } else if(ModalCommon?.title === "Add Book" && ModalCommon?.type === "Add Book") {
            if(process.env.REACT_APP_TITLE === "motivate on pape") {
                if(addBooksState.name !== "" && addBooksState.description !== "" && addBooksState.product_image !== "" && addBooksState.product_file !== "" && addBooksState.price !== ""){
                    const jsonData = JSON.stringify({
                        name: addBooksState.name,
                        description: addBooksState.description,
                        price: addBooksState.price,
                        product_image: addBooksState.product_image,
                        product_file: addBooksState.product_file
                    });
                    window.axios.post(ADD_PRODUCT_API_URL, jsonData, {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                        }
                    }).then(function (result) {
                        dispatch(AddProduct(result?.data?.data));
                        setModalCommon({...ModalCommon, 
                            open: false,
                            title: "",
                            submitbtn: "",
                            cancelbtn: "",
                            modaltype: "",
                            type: "",
                            bookdetails: "",
                        });
                        toast.success("Poster added successfully");
                        setAddBooksState({...addBooksState, name: "", description: "",  product_image: "", product_file: "", price: ""})
                        setProfileUpload("");
                        setFileUpload("");
                        setselectedValue("");
                    })  
                    .catch(function (result) {
                        toast.error(result.response.data.error);
                        toast.error('Category ' + result.response.data.error.category);
                    });
                    setBookFieldError(false);
                } else {
                    setBookFieldError(true);
                }
            } else {
                if(addBooksState.name !== "" && addBooksState.description !== "" && addBooksState.category !== "" && addBooksState.product_image !== "" && addBooksState.product_file !== "" && addBooksState.price !== ""){
                    const jsonData = JSON.stringify({
                        name: addBooksState.name,
                        description: addBooksState.description,
                        price: addBooksState.price,
                        category: addBooksState.category,
                        product_image: addBooksState.product_image,
                        product_file: addBooksState.product_file
                    });
                    window.axios.post(ADD_PRODUCT_API_URL, jsonData, {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                        }
                    }).then(function (result) {
                        dispatch(AddProduct(result?.data?.data));
                        setModalCommon({...ModalCommon, 
                            open: false,
                            title: "",
                            submitbtn: "",
                            cancelbtn: "",
                            modaltype: "",
                            type: "",
                            bookdetails: "",
                        });
                        toast.success("Book added successfully");
                        setAddBooksState({...addBooksState, name: "", description: "", category: "", product_image: "", product_file: "", price: ""})
                        setProfileUpload("");
                        setFileUpload("");
                        setselectedValue("");
                    })  
                    .catch(function (result) {
                        toast.error(result.response.data.error);
                        toast.error('Category ' + result.response.data.error.category);
                    });
                    setBookFieldError(false);
                } else {
                    setBookFieldError(true);
                }
            }
            
        } else if(ModalCommon?.title === "Edit Book" && ModalCommon?.type === "Edit Book") {
            if(process.env.REACT_APP_TITLE === "motivate on pape") {
                if(addBooksState.name !== "" && addBooksState.description !== "" && addBooksState.product_image !== "" && addBooksState.product_file !== "" && addBooksState.price !== ""){
                    const jsonData = JSON.stringify({
                        product_id: ModalCommon?.bookdetails?.id,
                        name: addBooksState.name,
                        description: addBooksState.description,
                        price: addBooksState.price,
                        product_image: addBooksState.product_image,
                        product_file: addBooksState.product_file,
                    });
                    window.axios.post(EDIT_PRODUCT_LIST_API_URL, jsonData, {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                        }
                    }).then(function (result) {
                        dispatch(EditProduct(result?.data?.data));
                        setModalCommon({...ModalCommon, 
                            open: false,
                            title: "",
                            submitbtn: "",
                            cancelbtn: "",
                            modaltype: "",
                            type: "",
                            bookdetails: "",
                        });
                        toast.success("Poster updated successfully");
                        setAddBooksState({...addBooksState, name: "", description: "", product_image: "", product_file: "", price: ""})
                        setProfileUpload("");
                        setFileUpload("");
                        setselectedValue("");
                    })  
                    .catch(function (result) {
                        toast.error(result.response.data.error);
                    });
                    setBookFieldError(false);
                } else {
                    setBookFieldError(true);
                }
            } else {
                if(addBooksState.name !== "" && addBooksState.description !== "" && addBooksState.category !== "" && addBooksState.product_image !== "" && addBooksState.product_file !== "" && addBooksState.price !== ""){
                    const jsonData = JSON.stringify({
                        product_id: ModalCommon?.bookdetails?.id,
                        name: addBooksState.name,
                        description: addBooksState.description,
                        price: addBooksState.price,
                        category: addBooksState.category,
                        product_image: addBooksState.product_image,
                        product_file: addBooksState.product_file,
                    });
                    window.axios.post(EDIT_PRODUCT_LIST_API_URL, jsonData, {
                        headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': 'Bearer ' + accessToken,
                        }
                    }).then(function (result) {
                        dispatch(EditProduct(result?.data?.data));
                        setModalCommon({...ModalCommon, 
                            open: false,
                            title: "",
                            submitbtn: "",
                            cancelbtn: "",
                            modaltype: "",
                            type: "",
                            bookdetails: "",
                        });
                        toast.success("Book updated successfully");
                        setAddBooksState({...addBooksState, name: "", description: "", category: "", product_image: "", product_file: "", price: ""})
                        setProfileUpload("");
                        setFileUpload("");
                        setselectedValue("");
                    })  
                    .catch(function (result) {
                        toast.error(result.response.data.error);
                        toast.error('Category ' + result.response.data.error.category);
                    });
                    setBookFieldError(false);
                } else {
                    setBookFieldError(true);
                }
            }
            
        } else if(ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is active product") {
            if(addBooksState.name !== "" && addBooksState.description !== "" && addBooksState.category !== "" && addBooksState.product_image !== "" && addBooksState.product_file !== "" && addBooksState.price !== ""){
                const jsonData = JSON.stringify({
                    product_id: ModalCommon?.bookdetails?.id,
                    is_active: ModalCommon?.selectedUser?.is_active === false ? 1 : 0, 
                });
                window.axios.post(EDIT_PRODUCT_LIST_API_URL, jsonData, {
                    headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                    }
                }).then(function (result) {
                    dispatch(EditProduct(result?.data?.data));
                    setModalCommon({...ModalCommon, 
                        open: false,
                        title: "",
                        submitbtn: "",
                        cancelbtn: "",
                        modaltype: "",
                        type: "",
                        bookdetails: "",
                    });
                    toast.success(result?.data?.msg);
                    setAddBooksState({...addBooksState, name: "", description: "", category: "", product_image: "", product_file: "", price: ""})
                    setProfileUpload("");
                    setFileUpload("");
                    setselectedValue("");
                })  
                .catch(function (result) {
                    toast.error(result.response.data.error);
                });
                setBookFieldError(false);
            } else {
                setBookFieldError(true);
            }
        } 
        else if(ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is delete book"){
            let getUrl = DELETE_PRODUCT + "/" + ModalCommon?.bookdetails?.id
            window.axios.get(getUrl, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                }
            }).then(function (result) {
                dispatch(deleteProduct(result?.data?.data))
                setModalCommon({
                    ...ModalCommon,
                    open: false,
                    title: "",
                    submitbtn: "",
                    cancelbtn: "",
                    modaltype: "",
                    type: "",
                    details: "",
                });
                toast.success(result?.data?.msg);
            })
            .catch(function (result) {
              toast.error(result?.response?.data?.error);
            });
        }
        else {
            setModalCommon({...ModalCommon, 
                open: false,
                title: "",
                submitbtn: "",
                cancelbtn: "",
                modaltype: "",
                type: "",
                bookdetails: "",
            });
        }
    };

    const onAddText = (e, type) => {
        if(type === "fname") {
            if(e.target.value === "") {
                setErrorFname("First name cannot be empty");
            } else {
                setErrorFname("");
            }
        } else if(type === "lname") {
            if(e.target.value === "") {
                setErrorLname("Last name cannot be empty");
            } else {
                setErrorLname("");
            }
        } else if (type === "password") {
            if (e.target.value === "") {
                setErrorPassword("Password cannot be empty");
            } else if (PasswordRegex.test(password) === false) {
                setErrorPassword("Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, one number, and one special character.");
            } else {
                setErrorPassword("");
            }
        }
    }

    // toggle switch button
    const ToggleSwitchBtn = (e, type) => {
        if(type === "Admin") {
            setIsAdmin(e.target.checked);
            setIsStaff(false);
        } else {
            setIsStaff(e.target.checked);
            setIsAdmin(false);
        }
    };

    // select permsissions
    const CategoryIDGetNew = [];
    const onSelectUser = (selectedList, selectedItem) => {
        selectedList.filter((elm) => {
            CategoryIDGetNew.push(elm);
        });
        setselectedValueUser(selectedList);
        setEditUser({...EditUser, permission: CategoryIDGetNew.toString()});
    }

    // remove permsissions
    const onRemoveUser = (selectedList, removedItem) => {
        selectedList.filter((elm) => {
            CategoryIDGetNew.push(elm);
        });
        setselectedValueUser(selectedList);
        setEditUser({...EditUser, permission: CategoryIDGetNew.toString()});
    }

    // select category
    const CategoryIDGet = [];
    const onSelect = (selectedList, selectedItem) => {
        selectedList.filter((elm) => {
            CategoryIDGet.push(elm?.id);
        });
        setselectedValue(selectedList);
        setAddBooksState({...addBooksState, category: CategoryIDGet.toString()})
    }

    const onRemove = (selectedList, removedItem) => {
        selectedList.filter((elm) => {
            CategoryIDGet.push(elm?.id);
        });
        setselectedValue(selectedList);
        setAddBooksState({...addBooksState, category: CategoryIDGet.toString()})
    }

    // close modal 
    const closeModal = () => {
        setTimeout(() => {
            setModalCommon({...ModalCommon, 
                open: false,
                title: "",
                submitbtn: "",
                cancelbtn: "",
                modaltype: "",
                type: "",
                bookdetails: "",
            });
            setAddBooksState({...addBooksState, name: "", description: "", category: "", product_image: "", product_file: "", price: ""})
            setProfileUpload("");
            setFileUpload("");
        }, 100);
    };

    // remove profile image
    const ImageRemoved = () => {
        setProfileUpload("");
        setAddBooksState({...addBooksState, product_image: ""});
    };

    // removed files
    const removedFileUpload = () => {
        setFileUpload("");
        setAddBooksState({...addBooksState, product_file: ""});
    };

    const preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    var validate = function(e, decimalPlaces) {
        // Get the input value
        let value = e.target.value;
            
        // Define a regular expression to match the format you want
        const regex = new RegExp(`^-?\\d+(\\.\\d{0,${decimalPlaces}})?$`);
        
        // Test the input value against the regular expression
        if (!regex.test(value)) {
            // If it doesn't match, remove any invalid characters
            value = value.replace(/[^0-9.-]/g, '');
            
            // Ensure only two decimal places
            const parts = value.split('.');
            if (parts[1] && parts[1].length > decimalPlaces) {
                parts[1] = parts[1].substring(0, decimalPlaces);
                value = parts.join('.');
            }
            
            // Update the input value
            e.target.value = value;
        }
    }

    // input field space issue 
    document.getElementById("productid")?.addEventListener('keydown', function (e) {
        if (this.value.length === 0 && e.which === 32) e.preventDefault();
    });
    document.getElementById("Categoryfield")?.addEventListener('keydown', function (e) {
        if (this.value.length === 0 && e.which === 32) e.preventDefault();
    });

    document.addEventListener("wheel", function(event){
        if(document.activeElement.type === "number" && document.activeElement.classList.contains("inputBoxs")) {
            document.activeElement.blur();
        }
    });

    return(<div className="ModalBackground"> 
        <div className={ModalCommon?.modaltype !== "form" ? "CommonPopupWrapper CommonPopupDelete" : "CommonPopupWrapper"}>
            <div className="commpopupheader">
                <h4>{ModalCommon?.title === "Add Book" ? (process.env.REACT_APP_TITLE === "motivate on pape" ? "Add Poster" : ModalCommon?.title) : ModalCommon?.title === "Edit Book" ? (process.env.REACT_APP_TITLE === "motivate on pape" ? "Edit Poster" : ModalCommon?.title) : ModalCommon?.title}</h4>
                <div className="closebtn" onClick={() => closeModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M18 6l-12 12"></path>
                        <path d="M6 6l12 12"></path>
                    </svg>
                </div>
            </div>
            {ModalCommon?.modaltype === "form" ? (<React.Fragment>
                {(ModalCommon?.title === "Edit Book" || ModalCommon?.title === "Add Book") ? (<div className="commonpopupbody">
                    <div className="form-Image-Upload">
                        {profileUpload === "" ? (<img src={process.env.REACT_APP_TITLE === "bundles of books" ? DummyBooksImage : DummyPostersImage} alt="upload image" />) : (<img src={profileUpload} onError={(e)=> {e.target.src = DummyImage}} alt="upload image" />)}
                        {profileUpload === "" ? (<label className="fileuplodbtn">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-camera" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"></path>
                                <path d="M12 13m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                            </svg>
                            {fileUploadLoader && (<img src={ImageLoader} style={{ backgroundColor: "#fff" }} alt="loader" />)}
                            <input type="file" onChange={UploadAvatarImage} accept=".svg, .png, .jpg, .jpeg, .webp"/>
                        </label>) : (<div className="fileuplodbtnss" onClick={() => ImageRemoved()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M18 6l-12 12"></path>
                                <path d="M6 6l12 12"></path>
                            </svg>
                        </div>)}
                        {bookFieldError && addBooksState.product_image === "" && <div className="danger-colorset">Please upload {process.env.REACT_APP_TITLE === "motivate on pape" ? "poster" : "book"} image</div>}
                    </div>
                    <div className="form-group">
                        <label>{process.env.REACT_APP_TITLE === "motivate on pape" ? "Poster Name" : "Book Name"} * </label>
                        <input type="text" className="form-control" id="productid" value={addBooksState.name} onChange={(e) => setAddBooksState({...addBooksState, name: e.target.value})} placeholder={process.env.REACT_APP_TITLE === "motivate on pape" ? "Enter Poster Name" : "Enter Book Name"} />
                        {bookFieldError && addBooksState.name === "" && <div className="danger-colorset">{process.env.REACT_APP_TITLE === "motivate on pape" ? "Poster" : "Book"} name cannot be empty</div>}
                    </div>
                    <div className="form-group">
                        <label>File *</label>
                        <label className={(addBooksState.product_file !== "" && addBooksState.product_file !== undefined) ? "uploadfileCustombtn active" : "uploadfileCustombtn"}>
                            {(addBooksState.product_file === "" && addBooksState.product_file !== undefined) ? <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-upload" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                <path d="M7 9l5 -5l5 5"></path>
                                <path d="M12 4l0 12"></path>
                            </svg> :
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-circle-check" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                <path d="M9 12l2 2l4 -4"></path>
                            </svg>}
                            {UploadLoader === true ? <div className="uploadingdots">
                                Uploading File
                                <span className="loader__dot">.</span> 
                                <span className="loader__dot">.</span> 
                                <span className="loader__dot">.</span>
                            </div> 
                            : (addBooksState.product_file !== ""  && addBooksState.product_file !== undefined) ? "File Uploaded !" : "Upload File"}
                            {fileUpload === "" && <React.Fragment>
                                {process.env.REACT_APP_TITLE === "bundles of books" ? (<input type="file" className="form-control" onChange={UploadBookFile} accept=".pdf, .xls, .ppt, .doc, .docx" />) :
                                (<input type="file" className="form-control" onChange={UploadBookFile} accept=".svg, .png, .jpg, .jpeg, .webp" />)}
                            </React.Fragment>}
                        </label>
                        {fileUpload !== "" && (<small className="filuplodername">
                            {ModalCommon?.title === "Edit Book" ? fileUpload?.original_name : fileUpload?.name}
                            <svg onClick={() => removedFileUpload()} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-xbox-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 21a9 9 0 0 0 9 -9a9 9 0 0 0 -9 -9a9 9 0 0 0 -9 9a9 9 0 0 0 9 9z"></path><path d="M9 8l6 8"></path><path d="M15 8l-6 8"></path></svg>
                        </small>)}
                        {(bookFieldError && addBooksState.product_file === "" && addBooksState.product_file !== undefined) && <div className="danger-colorset"> {process.env.REACT_APP_TITLE==="motivate on pape"?"Please upload poster file":" Please upload book file"}</div>}
                    </div>
                    {process.env.REACT_APP_TITLE !== "motivate on pape" && (<div className="form-group">
                        <label>Category Name *</label>
                        <div className="filedsearchReltive">
                            <Multiselect
                                options={categoryListShow} // Options to display in the dropdown
                                selectedValues={selectedValue} // Preselected value to persist in dropdown
                                onSelect={onSelect} // Function will trigger on select event
                                onRemove={onRemove} // Function will trigger on remove event
                                displayValue="name" // Property name to display in the dropdown options
                                singleSelect={true} // only single category select / for multiple category select remove singleSelect 
                            />
                        </div>
                        {bookFieldError && addBooksState.category === "" && <div className="danger-colorset">Book category cannot be empty</div>}
                    </div>)}
                    <div className="form-group">
                        <label>Description  *</label>
                        <DefaultEditor value={addBooksState.description} onChange={(e) => setAddBooksState({...addBooksState, description: e.target.value})} />
                        {bookFieldError && addBooksState.description === "" && <div className="danger-colorset">{process.env.REACT_APP_TITLE === "motivate on pape"? "Poster description cannot be empty" : "Book description cannot be empty"}</div>}
                    </div>
                    <div className="form-group">
                        <label>Price  *</label>
                        <input 
                            type="number" 
                            className="form-control inputBoxs" 
                            id="pricefield" 
                            value={addBooksState.price} 
                            onChange={(e) => setAddBooksState({...addBooksState, price: e.target.value})} 
                            min="0" 
                            onKeyPress={preventMinus}
                            onInput={(e) => validate(e, 2)}
                            placeholder="Enter Price" />
                        {bookFieldError && addBooksState.price === "" && <div className="danger-colorset"> {process.env.REACT_APP_TITLE === "motivate on pape"? "Poster price cannot be empty" : "Book price cannot be empty"}</div>}
                    </div>
                </div>) : (ModalCommon?.title === "Edit User" || ModalCommon?.title === "Add User") ? (<div className="commonpopupbody">
                    <div className="form-group">
                        <label>First Name *</label>
                        <input type="text" className="form-control" value={EditUser?.fname} onChange={(e) => setEditUser({...EditUser, fname: e.target.value.trim()})} onKeyUp={(e) => onAddText(e, "fname")} placeholder="Enter First Name" />
                        {errorFname !== "" && <div className="danger-colorset">{errorFname}</div>}
                    </div>
                    <div className="form-group">
                        <label>Last Name *</label>
                        <input type="text" className="form-control" value={EditUser?.lname} onChange={(e) => setEditUser({...EditUser, lname: e.target.value.trim()})} onKeyUp={(e) => onAddText(e, "lname")} placeholder="Enter Last Name" />
                        {errorLname !== "" && <div className="danger-colorset">{errorLname}</div>}
                    </div>
                    <div className="form-group">
                        <label>Email *</label>
                        <input type="text" className="form-control" value={EditUser?.email} onChange={(e) => setEditUser({...EditUser, email: e.target.value})} placeholder="Enter Email" />
                        {EmailRegex.test(EditUser?.email) === false && <div className="danger-colorset">{errorEmail}</div>}
                    </div>
                    {ModalCommon?.title === "Add User" && ModalCommon?.type === "Add User" && (<div className="form-group">
                        <label>Password *
                            <div className="passwordnotewrapper">
                                <ol>
                                    {/* Minimum 8 characters. */}
                                    <li>The alphabet must be between [a-z]</li>
                                    <li>At least one alphabet should be of Upper Case [A-Z]</li>
                                    <li>At least 1 number or digit between [0-9].</li>
                                    <li>At least 1 character from [ @ $ ! % * ? & ].</li>
                                </ol>
                                <img src={InfoIcon} alt="info icon" />
                            </div>
                        </label>
                        <div className="passwordform">
                            <input type="password" className="d-none" />
                            <input type={showPassword ? "text" : "password"} className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} onKeyUp={(e) => onAddText(e, "password")} placeholder="Enter Password" />
                            <button className="btn showpassworbtn" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 12m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0" /><path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7" /></svg>
                                    :
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-eye-off" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M3 3l18 18"></path><path d="M10.584 10.587a2 2 0 0 0 2.828 2.83"></path><path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341"></path></svg>
                                }
                            </button>
                        </div>
                        {errorPassword !== "" && <div className="danger-colorset">{errorPassword}</div>}
                    </div>)}
                    {((ModalCommon?.title === "Add User" && ModalCommon?.type === "Add User") || (ModalCommon?.title === "Edit User" && ModalCommon?.type === "Edit User")) && (<div className="switchbutton">
                        <div className="form-group">
                            <label>Admin Role :</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked={isAdmin} onChange={(e) => ToggleSwitchBtn(e, "Admin")} />
                            </label>
                        </div>
                        <div className="form-group">
                            <label>Staff Role :</label>
                            <label className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" checked={isStaff} onChange={(e) => ToggleSwitchBtn(e, "Staff")} />
                            </label>
                        </div>
                    </div>)}
                    {((ModalCommon?.title === "Add User" && ModalCommon?.type === "Add User") || (ModalCommon?.title === "Edit User" && ModalCommon?.type === "Edit User")) && (<>
                        {(isStaff && !isAdmin) && (<div className="form-group">
                            <label>Permissions *</label>
                            <div className="filedsearchReltive">
                                <Multiselect
                                    options={permissionlits} // Options to display in the dropdown
                                    selectedValues={selectedValueUser} // Preselected value to persist in dropdown
                                    onSelect={onSelectUser} // Function will trigger on select event
                                    onRemove={onRemoveUser} // Function will trigger on remove event
                                    displayValue={"value"} // Property name to display in the dropdown options
                                    singleSelect={false} // only single category select / for multiple category select remove singleSelect 
                                    isObject={false}
                                />
                            </div>
                            {(selectedValueUser?.length === 0 && errorPermission !== "") && <div className="danger-colorset">{errorPermission}</div>}
                        </div>)}
                    </>)}
                    <div className="form-group">
                        <label>Phone *</label>
                        <CountrySelectInput 
                            MobileNo={MobileNo}
                            setMobileNo={setMobileNo}
                            id={"accountflag"}
                        />
                        {MobileNo?.number?.toString()?.length !== 10 && <div className="danger-colorset">{MobileError}</div>}
                    </div>
                </div>) : (<div className="commonpopupbody">
                    <div className="form-group">
                        <label>Category Name *</label>
                        <input type="text" className="form-control" id="Categoryfield" value={categoryName} onChange={(e) => setCategoryName(e.target.value)} placeholder="Enter Category Name" />
                        {errorCategory && <div className="danger-colorset">{errorCategory}</div>}
                    </div>
                </div>)}
            </React.Fragment>) : (<div className="commonpopupbody">
                {ModalCommon?.title === "Delete Book" && (<img src={DeleteModalIcon} className="commondeleteicon" alt="delete" />)}
                <h4>{ModalCommon?.description} 
                    <div className="usertomodal">
                        {(ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is active product") ? ModalCommon?.selectedUser.name : 
                        (ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is active category") ? ModalCommon?.categorydetails.name : 
                        (ModalCommon?.title === "Are you sure?" && ModalCommon?.type === "is admin user" || ModalCommon?.type === "is staff user" || ModalCommon?.type === "is active user") ? ModalCommon?.selectedUser.email === null ? ModalCommon?.selectedUser.phone : ModalCommon?.selectedUser.email : ModalCommon?.selectedUser.phone}
                    </div>
                </h4>
            </div>)}
            <div className="commnfooter">
                <button type="button" className="btn successbtn" onClick={() => SubmitCommonBtn()}>{ModalCommon?.submitbtn}</button>
                <button type="button" className="btn cancelbtn" onClick={() => closeModal()}>{ModalCommon?.cancelbtn}</button>
            </div>
        </div>
    </div>);
};

export default CommonPopup;
