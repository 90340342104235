/* eslint-disable */
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { DASHBOARD_URL, BOOK_MANAGEMENT, CATEGOORY_MANAGEMENT, SUBSCRIBE_MANAGEMENT,CONTACT_MANAGEMENT, USER_MANAGEMENT, ORDER_MANAGEMENT, PAYMENT_MANAGEMENT, POSTER_MANAGEMENT } from "./Constant";
import MotivateOnPapeLogo from "../../assets/img/motivateonpapelogo.webp";
import BundleOfBookLogo from "../../assets/img/bundlesofbooks.webp";
import { useSelector } from "react-redux";

const Sidebar = () => {
    const { profiledata } = useSelector((state) => state.allReducers);
    const Location = useLocation();

    const closeSidbar = (e) => {
      e.preventDefault();
      document.getElementById("panel-sidebar").classList.remove("show-sidebar");
    }

    return(<aside id={"panel-sidebar"}
          className={process.env.REACT_APP_TITLE === "motivate on pape" ? "navbar navbar-vertical navbar-expand-lg navbar-light sidebar-left" : "navbar navbar-vertical navbar-expand-lg navbar-dark sidebar-left"}>
          <div className="container-fluid sidebar-container">
            <h1 className="navbar-brand navbar-brand-autodark">
              <a href=".">
                <img src={process.env.REACT_APP_TITLE === "motivate on pape" ? MotivateOnPapeLogo : BundleOfBookLogo} width="110" height="32" alt="Tabler" className="navbar-brand-image" />
              </a>
            </h1>
            <div>
          <svg onClick={(e) => closeSidbar(e)} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler  closebtn" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path d="M18 6l-12 12"></path>
            <path d="M6 6l12 12"></path>
          </svg>
        </div>
            <div className="collapse navbar-collapse" id="sidebar-menu">
              <ul className="navbar-nav pt-lg-3">
                <li className="nav-item">
                    <Link className={Location.pathname === DASHBOARD_URL ? "nav-link active" : "nav-link"} to={DASHBOARD_URL} >
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-dashboard" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 13m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                <path d="M13.45 11.55l2.05 -2.05"></path>
                                <path d="M6.4 20a9 9 0 1 1 11.2 0z"></path>
                            </svg>
                        </span>
                        <span className="nav-link-title">
                        Dashboard
                        </span>
                    </Link>
                </li>
                {(profiledata?.is_admin || (profiledata?.permission?.includes("product-list") || profiledata?.permission?.includes("add-product") || profiledata?.permission?.includes("update-product") || profiledata?.permission?.includes("delete-product"))) && (<li className="nav-item ">
                    <Link className={Location.pathname === BOOK_MANAGEMENT || Location.pathname === POSTER_MANAGEMENT ? "nav-link active" : "nav-link"} to={process.env.REACT_APP_TITLE === "motivate on pape" ? POSTER_MANAGEMENT : BOOK_MANAGEMENT} >
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-book-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M19 4v16h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12z"></path>
                                <path d="M19 16h-12a2 2 0 0 0 -2 2"></path>
                                <path d="M9 8h6"></path>
                            </svg>
                        </span>
                        <span className="nav-link-title">
                        {process.env.REACT_APP_TITLE === "motivate on pape" ? "Poster management" : "Books management"}
                        </span>
                    </Link>
                </li>)}
                {(profiledata?.is_admin || (profiledata?.permission?.includes("category-products-list") || profiledata?.permission?.includes("add-category-products") || profiledata?.permission?.includes("update-category-products"))) && (<>
                    {process.env.REACT_APP_TITLE === "bundles of books" && (<li className="nav-item ">
                      <Link className={Location.pathname === CATEGOORY_MANAGEMENT ? "nav-link active" : "nav-link"} to={CATEGOORY_MANAGEMENT} >
                          <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M4 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M4 13m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M14 4m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v3a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /><path d="M14 15m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v1a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" /></svg>
                          </span>
                          <span className="nav-link-title">
                          Category management
                          </span>
                      </Link>
                    </li>)}
                </>)}
                {(profiledata?.is_admin || (profiledata?.permission?.includes("user-list") || profiledata?.permission?.includes("add-user") || profiledata?.permission?.includes("update-user"))) && (<li className="nav-item">
                    <Link className={Location.pathname === USER_MANAGEMENT ? "nav-link active" : "nav-link"} to={USER_MANAGEMENT} >
                        <span className="nav-link-icon d-md-none d-lg-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-circle" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                                <path d="M12 10m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                <path d="M6.168 18.849a4 4 0 0 1 3.832 -2.849h4a4 4 0 0 1 3.834 2.855"></path>
                            </svg>
                        </span>
                        <span className="nav-link-title">
                        User management
                        </span>
                    </Link>
              </li>)}
              {(profiledata?.is_admin || (profiledata?.permission?.includes("contact-us-list") || profiledata?.permission?.includes("update-contact-us"))) && (<li className="nav-item">
                <Link className={Location.pathname === CONTACT_MANAGEMENT ? "nav-link active" : "nav-link"} to={CONTACT_MANAGEMENT} >
                  <span className="nav-link-icon d-md-none d-lg-inline-block">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                      <path d="M3 10l18 0"></path>
                      <path d="M7 15l.01 0"></path>
                      <path d="M11 15l2 0"></path>
                    </svg>
                  </span>
                  <span className="nav-link-title">
                    Contact management
                  </span>
                </Link>
              </li>)}
              {(profiledata?.is_admin || (profiledata?.permission?.includes("order-list"))) && (<li className="nav-item ">
                    <Link className={Location.pathname === ORDER_MANAGEMENT ? "nav-link active" : "nav-link"} to={ORDER_MANAGEMENT} >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
                      </span>
                      <span className="nav-link-title">
                      Ordered management
                      </span>
                    </Link>
              </li>)}
              {(profiledata?.is_admin || (profiledata?.permission?.includes("payment-list"))) && (<li className="nav-item">
                    <Link className={Location.pathname === PAYMENT_MANAGEMENT ? "nav-link active" : "nav-link"} to={PAYMENT_MANAGEMENT} >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-credit-card" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                          <path d="M3 10l18 0"></path>
                          <path d="M7 15l.01 0"></path>
                          <path d="M11 15l2 0"></path>
                        </svg>
                      </span>
                      <span className="nav-link-title">
                      Payment management
                      </span>
                    </Link>
                </li>)}
                {(profiledata?.is_admin || (profiledata?.permission?.includes("subscribe-list"))) && (<li className="nav-item">
                    <Link className={Location.pathname === SUBSCRIBE_MANAGEMENT ? "nav-link active" : "nav-link"} to={SUBSCRIBE_MANAGEMENT} >
                      <span className="nav-link-icon d-md-none d-lg-inline-block">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user-plus" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                          <path d="M16 19h6"></path>
                          <path d="M19 16v6"></path>
                          <path d="M6 21v-2a4 4 0 0 1 4 -4h4"></path>
                        </svg>
                      </span>
                      <span className="nav-link-title">
                        Subscribe management
                      </span>
                    </Link>
                </li>)}
              </ul>
            </div>
          </div>
        </aside>)
}

export default Sidebar;