/* eslint-disable */
import { ActionTypes } from "../../redux/actions";

const initailData = {
    is_login: "no",
    profiledata: {},
    accessToken: "",
    userId: "",
    userList: [],
    userObjects: {},
    categorylist: [],
    productlist: [],
    categoryObjects: {},
    orderlist: [],
    productObjects: {},
    paymentlist: [],
    contactList: [],
    permissionlits: [],
    subscribesList: []
}

export const posterReducers = (state = initailData, action) => {
    switch(action.type) {
        case ActionTypes.IS_LOGIN :
            return { 
                ...state, 
                is_login : action.payload, 
            };
        case ActionTypes.GET_PROFILE_USER :
            return { 
                ...state, 
                profiledata : action.payload, 
            };
        case ActionTypes.USER_ID :
            return { 
                ...state, 
                userId : action.payload, 
            };
        case ActionTypes.GET_SUBSCRIBE_LIST :
            return {
                ...state,
                subscribesList : action.payload,
            }
        case ActionTypes.ACCESS_TOKEN :
            return { 
                ...state, 
                accessToken : action.payload, 
            };
        case ActionTypes.USER_LIST :
            return { 
                ...state, 
                userList : action.payload, 
            };
        case ActionTypes.GET_ORDER_LIST :
            return { 
                ...state, 
                orderlist : action.payload, 
            };
        case ActionTypes.ID_WISE_USERS : 
            const UserObjectBase = state.userList;
            let arraynew = {};
            UserObjectBase && UserObjectBase.map((elm) => {
                arraynew[elm.id] = elm;
            });

            return {
                ...state,
                userObjects : arraynew
            };
        case ActionTypes.ADD_USER:
            const userslists = [...state.userList];
            userslists.unshift(action.payload);

            return {
                ...state,
                userList: userslists,
            }
        case ActionTypes.UPDATE_USER : 
            const UserListArray = [...state.userList];
            const UpdateUserListArray = UserListArray.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        first_name : action.payload.first_name,
                        last_name : action.payload.last_name,
                        phone : action.payload.phone,
                        email : action.payload.email,
                        is_active : action.payload.is_active,
                        is_admin: action.payload.is_admin,
                        is_staff: action.payload.is_staff,
                        permission: action.payload.permission
                    }
                } 
                return elm;
            });

            return {
                ...state,
                userList : UpdateUserListArray,
            }
        case ActionTypes.GET_CATEGORY : 
            return {
                ...state,
                categorylist: action.payload,
            }
        case ActionTypes.ADD_CATEGORY : 
            const categories = [...state.categorylist];
            categories.unshift(action.payload);
            
            return {
                ...state,
                categorylist: categories,
            }
        case ActionTypes.EDIT_CATEGORY : 
            const categoriesold = [...state.categorylist];
            const categoriesUpdate = categoriesold.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name: action.payload.name,
                        is_active: action.payload.is_active,
                    }
                } else 
                return elm;
            })
            
            return {
                ...state,
                categorylist: categoriesUpdate,
            }
        case ActionTypes.ID_WISE_CATEGORY :
            const categorylists = [...action.payload];
            let arraycategory = {};
            categorylists && categorylists.map((elm) => {
                arraycategory[elm.id] = elm;
            });

            return {
                ...state,
                categoryObjects : arraycategory
            };
        case ActionTypes.GET_PRODUCT_LIST :             
            return {
                ...state,
                productlist: action.payload,
            }
        case ActionTypes.GET_PAYMENT_LIST :             
            return {
                ...state,
                paymentlist: action.payload,
            }
        case ActionTypes.ID_WISE_PRODUCTS : 
            const ProductObjectBase = [...action.payload];
            let arrayproduct = {};
            ProductObjectBase && ProductObjectBase.map((elm) => {
                arrayproduct[elm.id] = elm;
            });

            return {
                ...state,
                productObjects : arrayproduct,
            };
        case ActionTypes.ADD_PRODUCT : 
            const products = [...state.productlist];
            products.unshift(action.payload);
            return {
                ...state,
                productlist: products,
            }
        case ActionTypes.EDIT_PRODUCT :
            const product = [...state.productlist];
            const UpdateProductList = product.map((elm) => {
                if(elm?.id === action.payload.id) {
                    return{
                        ...elm,
                        name: action.payload.name,
                        price: action.payload.price,
                        product_file: action.payload.product_file,
                        product_file_path: action.payload.product_file_path,
                        product_image: action.payload.product_image,
                        product_img_path: action.payload.product_img_path,
                        updated_at: action.payload.updated_at,
                        created_at: action.payload.created_at,
                        is_active: action.payload.is_active,
                        category: action.payload.category,
                        description: action.payload.description,
                    }
                } else 
                return elm;
            });
            return {
                ...state,
                productlist: UpdateProductList,
            }
        case ActionTypes.DELETE_PRODUCT:
            let getUpdated = state?.productlist.filter((item)=> item?.id !== action?.payload?.id);
            return {
                ...state,
                productlist: getUpdated,
            }
        case ActionTypes.CONTACT_LIST:
            return {
                ...state,
                contactList: action.payload,
            };
        case ActionTypes.PERMISSION_LIST_GET:
            return {
                ...state,
                permissionlits: action.payload.filter((elm) => elm !== "none"),
            }
        case ActionTypes.LOGOUT_USER:
            return initailData;
        default:
            return state;
    }
}