/* eslint-disable */
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { DASHBOARD_URL, BOOK_MANAGEMENT, GET_PERMISTIONS, SUBSCRIBE_MANAGEMENT, POSTER_MANAGEMENT, CONTACT_MANAGEMENT, GET_PRODUCT_LISTS_API_URL, CATEGOORY_MANAGEMENT, GET_CATEGORY_LISTS_API_URL, USER_MANAGEMENT, ORDER_MANAGEMENT, PAYMENT_MANAGEMENT, LOGIN_URL } from "./components/Shared/Constant";
import "./assets/css/style.css";
import "./assets/css/responsive.css";
import Sidebar from "./components/Shared/Sidebar";
import Header from "./components/Shared/Header";
import Dashboard from "./components/Dashboard";
import BookManagement from "./components/BookManagement";
import CategoryManagement from './components/CategoryManagement';
import UserManagement from './components/UserManagement';
import OrderedManagement from './components/OrderedManagement';
import PaymentManagement from './components/PaymentManagement';
import ContactManagement from './components/ContactManagement';
import Login from './components/Login';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { getIdWiseCategoryList, GetProductIdWiseList, getPermissionList, getAccessToken, checkLogin, logoutUser } from './redux/actions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubscribeManagement from './components/SubscribeManagement';
window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

function App() {
  const { accessToken, profiledata, is_login } = useSelector((state) => state.allReducers);
  const dispatch = useDispatch();

  useEffect(()=>{
    if(accessToken !== "") {
      getPermistions();
    }
  },[ accessToken ])

  // get contact list
  const getPermistions = () => {
      window.axios.get(`${GET_PERMISTIONS}`, {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + accessToken,
          }
      }).then(function (result) {
        dispatch(getPermissionList(result?.data?.data));
      })
      .catch(function (result) {
          dispatch(getPermissionList([]));
          if(result?.response?.status === 403) {
            dispatch(logoutUser());
          }
      });
  };

  useEffect(() => {
    if(accessToken !== "") {
      if(process.env.REACT_APP_TITLE === "bundles of books") {
          window.axios.get(`${GET_CATEGORY_LISTS_API_URL}/all/5000/1/none`, {
              headers: {
                  'Content-Type': 'application/json',
                  'Accept': 'application/json',
                  'Authorization': 'Bearer ' + accessToken,
              }
          }).then(function (result) {
              dispatch(getIdWiseCategoryList(result.data.data));
          })  
          .catch(function (result) {
              dispatch(getIdWiseCategoryList([]));
              if(result.response.data.detail === "Token expired.") {
                  localStorage.removeItem("accessToken");
                  dispatch(getAccessToken(""));
                  dispatch(checkLogin("no"));
                  dispatch(logoutUser());
              }
          });
      }
      window.axios.get(`${GET_PRODUCT_LISTS_API_URL}/all/5000/1/none`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + accessToken,
        }
      }).then(function (result) {
          dispatch(GetProductIdWiseList(result.data.data));
      })  
      .catch(function (result) {
          dispatch(GetProductIdWiseList([]));
          if(result.response.data.detail === "Token expired.") {
              localStorage.removeItem("accessToken");
              dispatch(getAccessToken(""));
              dispatch(checkLogin("no"));
              dispatch(logoutUser());
          }
      });
    }

    if(process.env.REACT_APP_TITLE === "motivate on pape") {
      document.querySelector("link[rel*='icon']").href = require("./assets/img/favicon_mp.png");
    } else {
      document.querySelector("link[rel*='icon']").href = require("./assets/img/favicon_bb.png");
    };
    
  }, [ accessToken ]);

  return (
    <div className="page">
      <BrowserRouter>
        {(accessToken !== "" && is_login !== "no") && <Sidebar />}
        <div className="page-wrapper">
            {(accessToken !== "" && is_login !== "no") && <Header profiledata={profiledata} accessToken={accessToken} />}
            <Routes>
              <Route path='*' element={(accessToken !== "" && is_login !== "no") ? <Navigate replace to={DASHBOARD_URL} /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path="/" element={(accessToken === "" && is_login === "no") ? <Login /> : <Navigate replace to={DASHBOARD_URL} />} >
                <Route path={LOGIN_URL} element={(accessToken === "" && is_login === "no") ? <Login /> : <Navigate replace to={DASHBOARD_URL} />} />
              </Route>
              <Route path="/" element={(accessToken !== "" && is_login !== "no") ? <Dashboard /> : <Navigate replace to={LOGIN_URL} />} >
                <Route index path={DASHBOARD_URL} element={(accessToken !== "" && is_login !== "no") ? <Dashboard /> : <Navigate replace to={LOGIN_URL} />} />
              </Route>
              <Route path={BOOK_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <BookManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={POSTER_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <BookManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={CATEGOORY_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <CategoryManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={USER_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <UserManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={CONTACT_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <ContactManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={ORDER_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <OrderedManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={PAYMENT_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <PaymentManagement /> : <Navigate replace to={LOGIN_URL} />} />
              <Route path={SUBSCRIBE_MANAGEMENT} element={(accessToken !== "" && is_login !== "no") ? <SubscribeManagement /> : <Navigate replace to={LOGIN_URL} />} />
            </Routes>
        </div>
      </BrowserRouter>
      <ToastContainer />
    </div>
  );
}

export default App;
