/* eslint-disable */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { GET_PAYMENT_LIST_API_URL } from "../Shared/Constant";
import { GetPaymentList } from "../../redux/actions";
import PaginationPage from "../Common/PaginationPage";
import moment from "moment";
import CloseIcon from '../../assets/img/closebutton.png';
import CommonLoaderNew from "../Common/CommonLoaderNew";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const PaymentManagement = () => {
    const { accessToken, paymentlist } = useSelector((state) => state.allReducers);
    const dispatch = useDispatch();
    const [ orderType, setOrderType ] = useState("all");
    const [ listLimit, setListLimit ] = useState(10);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ OrderSearch, setOrderSearch ] = useState("");
    const [ paginationLength, setPaginationLength ] = useState({});
    const [ searchNone, setSearchNone ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setOrderSearch("");
        setSearchNone(true);
    };

    // category type
    const SelectProductTypeFnct = (e) => {
        setOrderType(e.target.value);
        setCurrentPage(1);
    };

    // product limit function
    const ProductLimit = (e) => {
        setListLimit(e.target.value);
        setCurrentPage(1);
    };
    
    useEffect(() => {
        GetPaymentListFnct();
    }, [ listLimit, currentPage, orderType, searchNone ]);

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                GetPaymentListFnct(1);
            } else if(OrderSearch === "" || OrderSearch?.length <= 1) {
                GetPaymentListFnct();
            };
        };
    };

    // get order list
    const GetPaymentListFnct = (pages) => {
        setListLoader(true);
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        window.axios.get(`${GET_PAYMENT_LIST_API_URL}/${orderType}/${listLimit}/${pages === undefined ? currentPage : pages}/${OrderSearch === "" ? "none" : OrderSearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            setSearchNone(false);
            dispatch(GetPaymentList(result.data.data));
            setPaginationLength(result.data.pagination);
        })  
        .catch(function (result) {
            setListLoader(false);
            dispatch(GetPaymentList([]));
        });
    };

    return(<div className="col-sm-12 col-lg-12">
    <div className="card-body">
        <div className="card-header-New">
            <div className="searchBoxwithbtn">
            {OrderSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                <input type="text" className="form-control" value={OrderSearch} onKeyDown={(e) => onEnterSearch(e)} onChange={(e) => setOrderSearch(e.target.value)} placeholder="Search Payment (Cardholder Name & Amount)"/>
                {OrderSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                    <path d="M21 21l-6 -6"></path>
                </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
            </div>  
            <div className="card-header-right">
                <select className="form-control activestatusselet" value={orderType} onChange={(e) => SelectProductTypeFnct(e)}>
                    <option value="all">All</option>
                    <option value="paid">Paid</option>
                    <option value="unpaid">Unpaid</option>
                </select>
                <select className="form-control userLimitselect" value={listLimit} onChange={(e) => ProductLimit(e)}>
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                    <option value="40">40</option>
                    <option value="50">50</option>
                    <option value="60">60</option>
                    <option value="70">70</option>
                    <option value="80">80</option>
                    <option value="90">90</option>
                    <option value="100">100</option>
                </select>
            </div>          
        </div>
        <div className="card-table-new">
            <div className="card-table table-responsive">
                <table className="table table-vcenter card-table">
                    <thead>
                        <tr>
                            <th>Cardholder Name</th>
                            <th>Card Last digit No</th>
                            <th>Payment Date</th>
                            <th style={{ textAlign: "center" }}>Transaction ID</th>
                            <th>Amount</th>
                            <th>Payment Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {paymentlist && paymentlist?.length === 0 ? (<tr>
                            <td colSpan="10" style={{ textAlign: "center" }}>No Record Found</td>
                        </tr>) : paymentlist?.map((elm, index) => {
                            return(<tr key={index}>
                                <td>{elm.cardholder_name}</td>
                                <td>{elm.card_number}</td>
                                <td>{timeSince(new Date(elm?.created_at))}</td>
                                <td style={{ textAlign: "center" }}>{(elm.transaction_id === null || elm.transaction_id === "") ? "-" : elm.transaction_id}</td>
                                <td>${elm.amount}</td>
                                <td>
                                {elm.is_paid ? (<div className="infostatus">
                                        <span className="badge bg-green-lt">Paid</span>
                                    </div>) : (<div className="infostatus">
                                        <span className="badge bg-red-lt">Declined</span>
                                        <div className="toolktis">
                                            <span className="erroMessagwrapper">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-circle-filled" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M12 2c5.523 0 10 4.477 10 10a10 10 0 0 1 -19.995 .324l-.005 -.324l.004 -.28c.148 -5.393 4.566 -9.72 9.996 -9.72zm0 9h-1l-.117 .007a1 1 0 0 0 0 1.986l.117 .007v3l.007 .117a1 1 0 0 0 .876 .876l.117 .007h1l.117 -.007a1 1 0 0 0 .876 -.876l.007 -.117l-.007 -.117a1 1 0 0 0 -.764 -.857l-.112 -.02l-.117 -.006v-3l-.007 -.117a1 1 0 0 0 -.876 -.876l-.117 -.007zm.01 -3l-.127 .007a1 1 0 0 0 0 1.986l.117 .007l.127 -.007a1 1 0 0 0 0 -1.986l-.117 -.007z" strokeWidth="0" fill="currentColor"></path>
                                                </svg>
                                            </span>
                                            <div className="tooltpimessae">{JSON.parse(elm?.gwres?.replaceAll("'",'"'))?.responsetext}</div>
                                        </div>
                                    </div>)}
                                </td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <PaginationPage 
                paginationLength={paginationLength}
                totalPages={paginationLength?.total_records}
                currentPage={currentPage}
                onChangePage={currentFunction}
            />
        </div>
    </div>
    {ListLoader && (<CommonLoaderNew />)}
</div>)
}

export default PaymentManagement;