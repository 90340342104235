import React from "react";
import DummyImage from '../../../assets/img/dummyimage.png';
import DummyProductImage from '../../../assets/img/DefaultProduct.jpg';

const PreviewFiles = (props) => {
    const { type, url } = props;
    if(url !== undefined && url !== null) {
        return(<div className="previewImage">
            <img src={url} onError={(e)=> {e.target.src = type === "image" ? DummyImage : DummyProductImage}} alt="preview" />
        </div>)
    } else {
        return(<div className="previewImage">
            <img src={DummyImage} onError={(e)=> {e.target.src = type === "image" ? DummyImage : DummyProductImage}} alt="preview" />
        </div>)
    }
}

export default PreviewFiles;