/* eslint-disable */
import React, { useEffect, useState } from "react";
import PaginationPage from "../Common/PaginationPage";
import { getContactlist, getIdWiseUserList } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { GET_CONTACT_LIST_API_URL, GET_CONTACT_US_UPDATE, LOGIN_URL } from "../Shared/Constant";
import CloseIcon from '../../assets/img/closebutton.png';
import { useNavigate } from "react-router-dom";
import moment from "moment";
import CommonLoaderNew from "../Common/CommonLoaderNew";

export const timeSince = (dateParam)=> {
    if (!dateParam) {
        return null;
    }
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
    
    if (seconds < 5) {
        return 'Today';
    } else if (seconds < 60) {
        return `Today`;
    } else if (seconds < 90) {
        return 'Today';
    } else if (minutes < 60) {
        return `Today`;
    } else if (isToday) {
        return 'Today'; // Today at 10:20
    } else if (isYesterday) {
        return 'Yesterday'; // Yesterday at 10:20
    } else if (isThisYear) {
        return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January at 10:20
    }
    return moment.utc(dateParam).local().format('MMM D, YYYY'); // 10. January 2017. at 10:20
};

const ContactManagement = () => {
    const { accessToken, contactList, profiledata } = useSelector((state) => state.allReducers);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [userType, setUserType] = useState("all");
    const [userLimit, setUserLimit] = useState(10);
    const [userSearch, setUserSearch] = useState("");
    const [paginationLength, setPaginationLength] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [ searchNone, setSearchNone ] = useState(false);
    const [ ListLoader, setListLoader ] = useState(false);

    // page change
    const currentFunction = (page) => {
        setCurrentPage(page);
    };

    // clear search box
    const ClearSearchFiled = () => {
        setUserSearch("");
        setSearchNone(true);
    };

    const selectLimitProduct = (value) => {
        setUserLimit(value);
        setCurrentPage(1);
    };

    // select user type
    const SelectUserTypeFnct = (e) => {
        setUserType(e.target.value);
        setCurrentPage(1);
    };

    useEffect(() => {
        getContacts();
    }, [userType, userLimit, currentPage, searchNone ]);

    // on Enter search
    const onEnterSearch = (e) => {
        const code = e.which || e.keyCode;
        if(code !== 17 && code !== 18) {
            if(code === 13) {
                getContacts(1);
            } else if(userSearch === "" || userSearch?.length <= 1) {
                getContacts();
            };
        };
    };

    useEffect(() => {
        dispatch(getIdWiseUserList(contactList));
    }, [contactList]);

    // get user list
    const getContacts = (pages) => {
        setListLoader(true);
        if(pages !== undefined) {
            setCurrentPage(pages);
        };
        window.axios.get(`${GET_CONTACT_LIST_API_URL}/${userType}/${userLimit}/${pages === undefined ? currentPage : pages}/${userSearch === "" ? "none" : userSearch}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            setListLoader(false);
            dispatch(getContactlist(result.data.data));
            setPaginationLength(result.data.pagination);
            setSearchNone(false);
        }).catch(function (result) {
            setListLoader(false);
            dispatch(getContactlist([]));
            if(result?.response?.status === 403) {
                // dispatch(clearRedux());
                navigate(LOGIN_URL);
            }
        });
    };

    const replyStatus = (e, item) => {
        e.preventDefault();
        let getPatam = JSON.stringify({ "contact_id": item.id, "is_contacted": "1" })
        window.axios.post(GET_CONTACT_US_UPDATE, getPatam, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        }).then(function (result) {
            getContacts(); // update contact list after status
        }).catch(function (result) {  // If token is expired
            if(result?.response?.status === 403) {
                dispatch(clearRedux());
                navigate(LOGIN_URL);
            }
        });
    }

    return (<div className="col-sm-12 col-lg-12">
        <div className="card-body">
            <div className="card-header-New">
                <div className="searchBoxwithbtn" style={{ width: "325px" }}>
                {userSearch !== "" && (<label id="focusLabel" className="focusLabel">Search after hitting the enter button.</label>)}
                    <input type="text" className="form-control" value={userSearch} onKeyDown={(e) => onEnterSearch(e)} onChange={(e) => setUserSearch(e.target.value)} placeholder="Search Contact (First name, last name, email)..." />
                    {userSearch === "" ? (<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                        <path d="M21 21l-6 -6"></path>
                    </svg>) : (<img src={CloseIcon} className="icon searchclear" onClick={() => ClearSearchFiled()} alt="close" />)}
                </div>
                <div className="card-header-right">
                    <select className="form-control activestatusselet" value={userType} onChange={(e) => SelectUserTypeFnct(e)}>
                        <option value="all">All</option>
                        <option value="contacted">Replied</option>
                        <option value="uncontacted">Unreplied</option>
                    </select>
                    <select className="form-control userLimitselect" value={userLimit} onChange={(e) => selectLimitProduct(e.target.value)}>
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="40">40</option>
                        <option value="50">50</option>
                        <option value="60">60</option>
                        <option value="70">70</option>
                        <option value="80">80</option>
                        <option value="90">90</option>
                        <option value="100">100</option>
                    </select>
                </div>
            </div>
            <div className="card-table-new">
                <div className="card-table table-responsive">
                    <table className="table table-vcenter card-table">
                        <thead>
                            <tr>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>From Email</th>
                                <th>To Email</th>
                                <th style={{ width: "160px" }}>Message</th>
                                <th>Created At</th>
                                <th>Updated At</th>
                                {(profiledata?.is_admin || (profiledata?.permission?.includes("update-contact-us"))) && (<th>Action</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {contactList && contactList?.length === 0 ? (<tr>
                                <td colSpan="10" style={{ textAlign: "center" }}>No Contact Record Found</td>
                            </tr>) : contactList?.map((user, index) => {
                                    return (<tr key={index}>
                                        <td>{user?.user_fname === null ? "-" : user?.user_fname}</td>
                                        <td>{user?.user_lname === null ? "-" : user?.user_lname}</td>
                                        <td>{user?.from_email}</td>
                                        <td>{user?.to_email}</td>
                                        <td className="contact-message">{user?.message}</td>
                                        <td>{timeSince(new Date(user.created_at))}</td>
                                        <td>{timeSince(new Date(user.updated_at))}</td>
                                        {(profiledata?.is_admin || (profiledata?.permission?.includes("update-contact-us"))) && (<td>
                                            {
                                                user.is_contacted ? (
                                                    <h5>Replied</h5>
                                                ) : (
                                                    <button className="btn-reply" onClick={(e) => replyStatus(e, user)}>Reply</button>
                                                )
                                            }
                                        </td>)}
                                    </tr>)
                                
                            })}
                        </tbody>
                    </table>
                </div>
                <PaginationPage 
                    paginationLength={paginationLength}
                    totalPages={paginationLength?.total_records}
                    currentPage={currentPage}
                    onChangePage={currentFunction}
                />
            </div>
        </div>
        {ListLoader && (<CommonLoaderNew />)}
    </div>)
}

export default ContactManagement;